import { useState, useContext, useMemo, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import EditIcon from '@mui/icons-material/Edit'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import CreateOrEditCarrierDialog from './CreateOrEditCarrierDialog'
import { CarrierData } from './CarrierManager.type'

interface CarrierTableProps {
  carriers: CarrierData[]
  onChange?: () => void
}

const CarrierTable = ({ carriers, onChange }: CarrierTableProps): ReactElement => {
  const { t } = useTranslation()
  const [activeCarrier, setActiveCarrier] = useState<CarrierData>()
  const [createOrEditCarrierDialogOpen, setCreateOrEditCarrierDialogOpen] = useState(false)

  const handleEditClick = useCallback((row: CarrierData) => {
    setActiveCarrier(row)
    setCreateOrEditCarrierDialogOpen(true)
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: t('screens.cs.carrier_manager.card.columns.name'),
        getValue: (row: CarrierData) => {
          return <KNTypography variant={'p3'} color="text.main" >{row.name}</KNTypography>

        },
        sx: { width: { xs: '24rem', sm: 'auto' } },
      },
      {
        name: 'customerIdentifier',
        label: t('screens.cs.carrier_manager.card.columns.customer_identifier'),
        getValue: (row: CarrierData) => {
            return <KNTypography variant={'p3'} color="text.main" component="p" noWrap>{row.customerIdentifier}</KNTypography>

        },
        sx: { width: { xs: '10rem', xl: '16rem' } },
      },
      {
        name: 'integration',
        label: t('screens.cs.carrier_manager.card.columns.integration'),
        getValue: (row: CarrierData) => {
          if (row.integration === 'NONE') {
            return <KNTypography variant={'p5'} color="primary.light">{t('general.none')}</KNTypography>
          }
          return t(`screens.cs.carrier_manager.integrations.${row.integration}`)
        },
        sx: { width: '10rem' },
      },
      {
        name: 'telematics',
        label: t('screens.cs.carrier_manager.card.columns.telematics'),
        getValue: (row: CarrierData) => {
          if (row.telematics === 'NONE') {
            return <KNTypography variant={'p5'} color="primary.light">{t('general.none')}</KNTypography>
          }
          return t(`screens.cs.carrier_manager.telematics.${row.telematics}`)
        },
        sx: { width: '10rem' },
      },
      {
        name: 'features',
        label: t('screens.cs.carrier_manager.card.columns.features'),
        getValue: (row: CarrierData) => {
          // NOTE: simplified handling of a current singular feature, might be expanded in the future
          if (!row.recommendedRoutes) {
            return <KNTypography variant={'p5'} color="primary.light">{t('general.none')}</KNTypography>
          }
          return t(`screens.cs.carrier_manager.add_edit_carrier.recommended_routes`)
        },
        sx: { width: '14rem' },
      },
    ],
    []
  )

  const actions = useMemo(
    () => [
      {
        name: 'edit',
        label: t('screens.cs.carrier_manager.card.actions.edit_short'),
        icon: <EditIcon />,
        onClick: handleEditClick,
      },
    ],
    []
  )

  const handleCreateOrEditDialogAction = useCallback((): void => {
    setCreateOrEditCarrierDialogOpen(false)
    onChange?.()
  }, [])

  const handleCreateOrEditDialogClose = useCallback((): void => {
    setCreateOrEditCarrierDialogOpen(false)
  }, [])

  return (
    <>
      <KNDataTable
        columns={columns}
        actions={actions}
        data={carriers}
        sx={{
          marginX: -2,
          fontSize: 8,
        }}
      />
      <CreateOrEditCarrierDialog
        payload={
          activeCarrier && {
            carrier: activeCarrier,
          }
        }
        open={createOrEditCarrierDialogOpen}
        onAction={handleCreateOrEditDialogAction}
        onClose={handleCreateOrEditDialogClose}
      />
    </>
  )
}

export default CarrierTable
