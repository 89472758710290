import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    load: 'currentOnly',
    fallbackLng: 'en',
    debug: ['development', 'test'].includes(process.env.NODE_ENV),
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnNull: false,
    compatibilityJSON: 'v3', // TODO: update translation files to v4
    lowerCaseLng: true,
    backend: {
      loadPath: `/locales/{{lng}}/translation.json?nocache=${Date.now()}`,
    },
  })

export default i18n
