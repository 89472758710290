import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

// Types
import { getFieldErrorMessages } from 'global/helpers/form'
import { KNFormTextProps } from './types'

const KNFormText = <T extends FieldValues>({
  name,
  placeholder,
  control,
  rules,
  onType,
  variant,
  label,
  size,
  sx,
  disabled,
  rows,
  type,
  readonly,
  startAdornment,
  endAdornment,
  transformValue,
}: KNFormTextProps<T>): ReactElement => {
  const getAdornment = (adornment: ReactElement | string, position: 'start' | 'end'): ReactElement => {
    if (typeof adornment === 'string') {
      return <InputAdornment position={position}>{adornment}</InputAdornment>
    }
    return adornment
  }

  const getStartAdornment = (): ReactElement | null => (startAdornment ? getAdornment(startAdornment, 'start') : null)

  const getEndAdornment = (): ReactElement | null => (endAdornment ? getAdornment(endAdornment, 'end') : null)

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
        <TextField
          data-test={name}
          fullWidth
          label={label}
          placeholder={placeholder}
          size={size}
          sx={sx}
          disabled={formState.isSubmitting || disabled}
          multiline={Boolean(rows && rows > 1)}
          rows={rows}
          inputRef={ref}
          value={value ?? ''}
          onChange={(event): void => {
            onChange(transformValue ? transformValue(event.target.value) : event.target.value)
            onType?.()
          }}
          error={invalid}
          helperText={getFieldErrorMessages(error)}
          InputProps={{
            type: type ?? 'text',
            startAdornment: getStartAdornment(),
            endAdornment: getEndAdornment(),
            disabled: readonly,
          }}
        />
      )}
    />
  )
}

export default KNFormText
