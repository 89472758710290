import { ReactElement } from 'react'
import { Marker as MapLibreMarker } from 'react-map-gl/maplibre'

// KN imports
import { Geofence, GeoPoint, GeoPointsGroup, StopsSequenceGroup } from 'screens/TripDetails/TripDetails.types'
import { StopsLocationGroup } from 'screens/TripDashboard/TripDashboard.types'
import { VehicleData } from 'screens/VehicleManager/VehicleManager.types'

export interface KNMapLibreProps<T = object> {
  markers: MapMarker[]
  geoPoints?: GeoPoint[]
  groupedGeoPoints?: GeoPointsGroup[]
  withHeading?: boolean
  onMarkerClick?: (marker: MapMarker) => void
  center?: GeoPoint
  zoom?: number
}

export interface KNMapLibreMarkerProps {
  marker: MapMarker
  onMarkerClick?: (marker: MapMarker) => void
}

export interface KNMapTimestampMarkerProps<T = object> {
  map: google.maps.Map
  geoPoints: GeoPoint[]
}

export interface KNMapLibreTooltipProps {
  markers: MapMarker[]
}

export enum MapMarkerState {
  Default,
  Active,
  Muted,
}

export type MapMarker = BasicMapMarker | SequenceGroupMapMarker | LocationGroupMapMarker

export interface BasicMapMarker {
  id: string
  latitude: number
  longitude: number
  instance?: typeof MapLibreMarker
  type?: string
  label?: string
  heading?: number
  geofence?: number // radius in meters
  color?: string
  state?: MapMarkerState
  tooltip?: ReactElement
  cluster?: string
}

export interface SequenceGroupMapMarker extends BasicMapMarker {
  sequenceGroup: StopsSequenceGroup
}

export interface LocationGroupMapMarker extends BasicMapMarker {
  locationGroup: StopsLocationGroup
}

export interface VehicleMapMarker extends BasicMapMarker {
  vehicle: VehicleData
  tripCids: string[]
}

export const hasSequenceGroup = (marker: MapMarker): marker is SequenceGroupMapMarker =>
  'sequenceGroup' in marker

export const hasLocationGroup = (marker: MapMarker): marker is LocationGroupMapMarker =>
  'locationGroup' in marker

export const hasVehicleData = (marker: MapMarker): marker is VehicleMapMarker =>
  'vehicle' in marker

export interface MapTooltip {
  id: string
  content: ReactElement
  priority?: number
}
