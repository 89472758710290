import { useState, useEffect, useCallback, useMemo, ReactElement, Fragment } from 'react'
import { throttle } from 'lodash'

// Map imports
import { useMap, MapLayerMouseEvent } from 'react-map-gl/maplibre'

// @mui imports
import Popper, { PopperProps } from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'

// KN imports
import { KNMapLibreTooltipProps, MapMarker, MapMarkerState, MapTooltip } from './types'
import { getMarkerIcon } from './helpers'

const getTooltipContent = (marker: MapMarker): ReactElement => {
  return (
    <Box paddingY={0.75} paddingLeft={1} paddingRight={2}>
      <Stack spacing={1} direction="row" alignItems="start">
        <Box sx={{ opacity: marker.state === MapMarkerState.Muted ? 0.3 : 1, width: '24px', height: '24px', flexShrink: 0 }}>
          {getMarkerIcon(marker)}
        </Box>
        <Box>
          {marker.tooltip}
        </Box>
      </Stack>
    </Box>
  )
}

const KNMapLibreTooltip = ({
  markers,
}: KNMapLibreTooltipProps): ReactElement | null => {
  const { current: map } = useMap()
  const [tooltips, setTooltips] = useState<MapTooltip[]>([])
  const [anchor, setAnchor] = useState<PopperProps['anchorEl'] | null>(null)

  useEffect(() => {
    map?.on('mousemove', handleTooltipMoveThrottled)
    map?.on('mousemove', handleTooltipContentThrottled)

    return () => {
      map?.off('mousemove', handleTooltipMoveThrottled)
      map?.off('mousemove', handleTooltipContentThrottled)
    }
  }, [map, markers])

  const handleTooltipMove = useCallback((event: MapLayerMouseEvent) => {
    setAnchor({
      getBoundingClientRect: () => new DOMRect(event.originalEvent.clientX, event.originalEvent.clientY, 0, 0),
    })
  }, [])
  const handleTooltipMoveThrottled = useMemo(() => throttle(handleTooltipMove, 16), [handleTooltipMove])

  const handleTooltipContent = useCallback((event: MapLayerMouseEvent) => {
    setTooltips(markers.map((marker): MapTooltip => {
      return {
        id: marker.id,
        content: getTooltipContent(marker),
      }
    }))
  }, [markers])
  const handleTooltipContentThrottled = useMemo(() => throttle(handleTooltipContent, 200, { leading: true, trailing: false }), [handleTooltipContent, markers])

  return (
    <Popper
      open={Boolean(anchor)}
      disablePortal={true}
      anchorEl={anchor}
      placement="auto-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [8, 8],
          },
        },
      ]}
      sx={{
        pointerEvents: 'none',
        zIndex: 2000,
      }}
    >
      <Paper elevation={4} sx={{ maxWidth: '20rem' }}>
        <Stack
          direction="column"
          divider={<Divider orientation="horizontal" flexItem />}
        >
          {tooltips.map((tooltip) => (
            <Fragment key={tooltip.id}>
              {tooltip.content}
            </Fragment>
          ))}
        </Stack>
      </Paper>
    </Popper>
  )
}

export default KNMapLibreTooltip
