import { useState, useEffect, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import MessageFilters from './MessageFilters'
import MessageTable from './MessageTable'
import { getRejectedMessages } from './MessageManager.service'
import { MessageData, MessageFiltersValues } from './MessageManager.types'

const RejectedMessages = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [messagesData, setMessagesData] = useState<MessageData[]>([])
  const [filteredMessagesData, setFilteredMessagesData] = useState<MessageData[]>([])

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    const messages = await getRejectedMessages()
    setMessagesData(messages)
    setFilteredMessagesData(messages)
    setLoading(false)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [])

  const filterData = useCallback((filters: MessageFiltersValues): void => {
    setFilteredMessagesData(
      messagesData.filter((message) => {
        const lowercaseKeywords = filters.keywords?.map((keyword: string) => keyword.toLowerCase())
        let keywordsCondition = true
        if (lowercaseKeywords?.length) {
          const lowercaseValues = [message.uuid].map((value: string) =>
            value.toLowerCase()
          )
          keywordsCondition = lowercaseValues
            .map((value) => {
              return lowercaseKeywords.map((keyword) => value.includes(keyword)).some((condition: boolean) => condition)
            })
            .some((condition: boolean) => condition)
        }

        return [keywordsCondition].every(
          (condition: boolean): boolean => condition
        )
      })
    )
  }, [messagesData])

  return (
    <Container maxWidth="xl" data-test="messages-container">
      {loading ? (
        <KNLoader>
          <KNTypography>{t('screens.cs.message_manager.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <Paper elevation={8} sx={{ padding: 2 }}>
          <Stack direction="column" spacing={1} mb={1}>
            <MessageFilters onChange={filterData} />
            <KNTypography data-test="messages-counter" variant="h6">
              {t('screens.cs.message_manager.messages_count', { count: filteredMessagesData.length })}
            </KNTypography>
          </Stack>
          <MessageTable messages={filteredMessagesData} />
        </Paper>
      )}
    </Container>
  )
}

export default RejectedMessages
