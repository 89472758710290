import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Stack from '@mui/material/Stack'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { LegData } from './TripDetails.types'

export interface UitReferenceProps {
  leg: LegData
  stacked?: boolean
}

const UitReference = ({ leg, stacked }: UitReferenceProps): ReactElement | null => {
  const { t } = useTranslation()

  if (!leg.attributes.uitReference || (leg.attributes.uitReference.isRequired && !leg.attributes.uitReference.code)) {
    return null
  }

  return (
    <Stack
      data-test="uit-reference"
      direction={stacked ? 'column' : 'row'}
      alignItems="start"
      spacing={stacked ? 0 : 0.5}
    >
      <KNTypography variant="p6" color="primary.light">
        {t('screens.cs.trip_details.card.uit_reference')}
      </KNTypography>
      <KNTypography color="text.main" fontSize="p3">
        {leg.attributes.uitReference.isRequired ? leg.attributes.uitReference.code : t('screens.cs.trip_details.card.uit_not_required')}
      </KNTypography>
    </Stack>
  )
}

export default UitReference
