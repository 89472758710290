import typography from 'assets/theme/base/typography'
import pxToRem from 'assets/theme/functions/pxToRem'

const { size, fontWeightSemiBold } = typography

// types
type Types = any

const dialogTitle: Types = {
  styleOverrides: {
    root: {
      padding: pxToRem(20),
      fontSize: size.lg,
      fontWeight: fontWeightSemiBold,
    },
  },
}

export default dialogTitle
