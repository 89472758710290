import { useContext, useEffect, useState, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom'

// @mui imports
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import ScheduleIcon from '@mui/icons-material/Schedule'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Link from '@mui/material/Link'
import Slide from '@mui/material/Slide'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SystemStyleObject } from '@mui/system'
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded'

// KN Components
import theme from 'assets/theme'
import KNChip from 'components/KN_Components/Base/KNChip/KNChip'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import {
  KNArrowDownIcon,
  KNCompanyIcon,
  KNHideMenuIcon,
  KNRevealIcon,
  KNUserIcon
} from 'components/KN_Molecules/KNIcon/KNIcon'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { getActiveMenu } from 'global/helpers/activeRoute'
import { getLogo } from 'global/helpers/getLogo'

// Data
import { getMenuOptions, getUserMenuOptions } from './DrawerMenu.helpers'

// Context
import { useMenuContext } from 'context/menu/MenuContext'
import { UserContext } from 'context/authentication/UserContext'
import { getSelectedCompany, getStartPage, setSelectedCompany } from 'context/authentication/User.helpers'

// Types
import { Company } from 'context/authentication/Company.types'

const DrawerMenu = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { user, logoutUser } = useContext(UserContext)
  const { menuState, dispatch } = useMenuContext()
  const { menuOpen, menuExtended } = menuState.menuContext
  const menuOptions = getMenuOptions()
  const userMenuOptions = getUserMenuOptions()

  const showCompanySelector = user?.companies
  const companySelectorClickable = user?.companies && user.companies.length > 1

  const location = useLocation()
  const [activeMenu, setActiveMenu] = useState<string | null>(null)
  useEffect(() => {
    setActiveMenu(getActiveMenu(location.pathname))
  }, [location])

  // Dropdown Menu
  const [anchorElCompanies, setAnchorElCompanies] = useState<null | HTMLElement>(null)
  const [anchorElUserMenu, setAnchorElUserMenu] = useState<null | HTMLElement>(null)
  const companyMenuOpen = Boolean(anchorElCompanies)
  const userMenuOpen = Boolean(anchorElUserMenu)
  const handleClickCompanies = (event: React.MouseEvent<HTMLElement>): void => {
    if (companySelectorClickable) setAnchorElCompanies(event.currentTarget)
  }
  const handleClickUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUserMenu(event.currentTarget)
  }
  const handleClose = useCallback((): void => {
    if (anchorElCompanies) setAnchorElCompanies(null)
    else if (anchorElUserMenu) setAnchorElUserMenu(null)
  }, [anchorElCompanies, anchorElUserMenu])

  const handleCloseDrawer = (): void => {
    dispatch({
      type: 'setMenuProps',
      payload: {
        menuExtended: menuExtended,
        menuOpen: false,
      },
    })
  }

  const truncateUserDisplayName = (string: string): string => {
    return string.length > 18 ? string.slice(0, 18 - 1) + '...' : string
  }

  const getSection = (sectionOption,index:number) =>{
    if(sectionOption.name === 'user') {
     return <Box key={index} display="flex" width="100%">
       <Box
         mx={-1}
         my={0.5}
         sx={{
           width: '8px',
           borderRadius: '0px 4px 4px 0px',
           backgroundColor: activeMenu === 'profile' ? 'background.activeNavRectangle' : null,
         }} />

       <Box
        data-guide="menu-profile"
        my={0.5}
        mx={2}
        py={1}
        px={1}
        sx={{
          width: menuExtended ? '92.5%' : '72px',
          display: isMobile ? 'inline-flex' : 'flex',
          cursor: 'pointer',
          alignItems: 'center',
          backgroundPosition: 'center',
          borderRadius: ({ borders }) => borders.borderRadius.lg,
          backgroundColor: activeMenu === 'profile' ? 'background.activeNavBackground' : null,
          transition: isMobile ? 'all 0.2s ease' : 'none',
          '&:hover': {
            color: 'primary.main',
            backgroundColor: 'background.contrastBackground',
          },
        }}
        onClick={handleClickUserMenu}
      >
       <KNUserIcon sx={{ mr: 1.5 }}></KNUserIcon>
          {menuExtended && user && (
            <KNTypography
              variant="p2"
            >
              {truncateUserDisplayName(user.displayName ?? user.email)}
            </KNTypography>
          )}

      </Box>
      <Menu
        anchorEl={anchorElUserMenu}
        id="user-menu"
        open={userMenuOpen}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <MenuItem
          sx={{
            width: '100%',
          }}
        >
          <Link component={RouterNavLink} to={'/profile'} data-test="drawer-menu-navigate-profile">
            <KNTypography>
              {t('drawer_menu.show_my_profile')}
            </KNTypography>
          </Link>
        </MenuItem>
        <MenuItem
          data-test={'logout-button'}
          onClick={(): void => {
            logoutUser()
            analyticsEvent('polestar_logout')
          }}
        >
          <Icon
            sx={({ typography: { size } }: Theme): SystemStyleObject<Theme> => ({
              mr: 0.5,
              fontSize: size.md,
            })}
          >
            logout
          </Icon>
          {t('drawer_menu.logout')}
        </MenuItem>
      </Menu>
     </Box>
    } else if(sectionOption.label==='company_name' && showCompanySelector) {
     return <Box key={index} display="flex" width="100%">
       <Box
         mx={-1}
         my={0.5}
         sx={{
           width: '8px',
           borderRadius: '0px 4px 4px 0px',
           backgroundColor: activeMenu === sectionOption.name ? 'background.activeNavRectangle' : null,
         }} />
       <Box
         data-guide="menu-company"
         my={0.5}
         mx={2}
         py={1}
         pl={1}
         sx={{
           width: menuExtended ? '92.5%' : '72px',
           display: isMobile ? 'inline-flex' : 'flex',
           justifyContent:"space-between",
           cursor: 'pointer',
           alignItems: 'center',
           backgroundPosition: 'center',
           borderRadius: ({ borders }) => borders.borderRadius.lg,
           backgroundColor: activeMenu === sectionOption.name ? 'background.activeNavBackground' : null,
           transition: isMobile ? 'all 0.2s ease' : 'none',
           '&:hover': {
             color: 'primary.main',
             backgroundColor: 'background.contrastBackground',
           },
         }}
         onClick={handleClickCompanies}
       >
         <KNCompanyIcon ></KNCompanyIcon>
         {menuExtended && (
           <KNTypography
             variant="p2"
           >
             {getSelectedCompany()?.displayName}
           </KNTypography>
         )}
         {companySelectorClickable && <KNArrowDownIcon sx={{ height: '15px' }} ></KNArrowDownIcon>}
       </Box>
          <Menu
            anchorEl={anchorElCompanies}
            id="company-menu"
            open={companyMenuOpen}
            onClose={handleClose}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
          >
            {user?.companies.map((company, index) => (
              <MenuItem
                key={index}
                onClick={(): void => handleSelectCompany(company)}
                data-test={`drawer-menu-companySelector-${company.displayName}`}
              >
                <ListItemText disableTypography>{company.displayName}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
        </Box>
    } else {
      const sectionOptionLabel: string = sectionOption.label
      return (<Link
          component={RouterNavLink}
          key={sectionOption.label}
          to={sectionOption.link}
          onClick={handleCloseDrawer}
          data-test={`drawer-menu-navigate-${sectionOptionLabel}`}
        >
          <Box display="flex" width="100%">
            <Box
              mx={-1}
              my={0.5}
              sx={{
                width: '8px',
                borderRadius: '0px 4px 4px 0px',
                backgroundColor: activeMenu === sectionOption.name ? 'background.activeNavRectangle' : null,
              }} />
            <Box
              my={0.5}
              mx={2}
              py={1}
              px={1}
              sx={{
                width: menuExtended ? '100%' : '40px',
                borderRadius: ({ borders }) => borders.borderRadius.lg,
                backgroundColor: activeMenu === sectionOption.name ? 'background.activeNavBackground' : null,
                color: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                transition: isMobile ? 'all 200ms ease-in-out' : 'none',
                '&:hover': {
                  color: 'primary.main',
                  backgroundColor: 'background.contrastBackground',
                },
              }}
            >

              <Icon color="inherit" sx={{ mr: 1.5 }} fontSize="medium">
                {sectionOption.icon}
              </Icon>
              {menuExtended && (
                <KNTypography variant="p2" color="inherit" sx={{ textTransform: 'capitalize' }}>
                  {t(sectionOption.label)}
                </KNTypography>
              )}
            </Box>
          </Box>
        </Link>)
    }
  }

  const truncateTimeZone = (input: string): string => {
    const string = input.split('/')
    let firstPart = string[0]
    let secondPart = string.slice(1).join('/')

    if (string.length === 1) {
      return input
    }

    if (input.length > 22) {
      firstPart = string[0].slice(0, 4)
    }

    if (input.length > 22) {
      secondPart = `${secondPart.slice(0, 7)}...${secondPart.slice(-7)}`
    }

    return `${firstPart}/${secondPart}`
  }

  const handleSelectCompany = useCallback((company: Company): void => {
    const previousSelectedCompany = getSelectedCompany()
    setSelectedCompany(company)
    analyticsEvent('polestar_selected_company_changed')
    let reload = false
    // Reload only if we have the same set of permissions
    if (
      previousSelectedCompany &&
      previousSelectedCompany.type === company.type &&
      previousSelectedCompany.role === company.role
    ) {
      reload = true
    }

    if (
      reload &&
      ((window.location.pathname === '/visibility-dashboard/air' && !company.modules.air_shipments) ||
        (window.location.pathname === '/visibility-dashboard/road' && !company.modules.road_shipments))
    )
      reload = false

    if (reload) {
      window.location.reload()
    } else {
      window.location.assign(getStartPage())
    }
  }, [])

  // Drawer Menu
  const handleDrawerMenuExtend = (): void => {
    dispatch({
      type: 'setMenuProps',
      payload: {
        menuOpen: menuOpen,
        menuExtended: !menuExtended,
      },
    })
    if (menuExtended) analyticsEvent('polestar_collapse_side_menu')
    else analyticsEvent('polestar_expand_side_menu')
  }

  const mobileWrapper = (): JSX.Element => {
    const drawerMenu = (
      <Box
        data-test="drawer-menu"
        sx={({ palette }: Theme): SystemStyleObject<Theme> => ({
          height: '100%',
          backgroundColor: palette.background.default,
          color: palette.primary.main,
          fontWeight: 300,
          p: 1,
          zIndex: 999,
          position: 'fixed',
          width: menuExtended ? '260px' : '100px',
          transition: 'width 0.2s',
          visibility: (isMobile && menuOpen) || !isMobile ? 'visible' : 'hidden',
          right: isMobile ? 0 : null,
        })}
      >
        <Box sx={{ position: 'relative', height: '100%' }}>
          <Box
            sx={{
              height: '32px',
              display: isMobile ? 'inline-flex' : 'flex',
            }}
          >
            {menuExtended ? getLogo('logo', '100%', '100%') : getLogo('symbol', '100%', '100%')}
            {isMobile && (
              <IconButton
                onClick={handleCloseDrawer}
                data-test={'drawer-menu-extendButton'}
                size={menuExtended ? 'small' : 'small'}
                sx={{ right: -10, position: 'absolute' }}
              >
                <MenuOpenRoundedIcon sx={{ transform: 'rotate(180deg)' }} />
              </IconButton>
            )}
          </Box>
          <Box my={4.5} sx={{ textAlign: 'left' }}>
            {menuOptions.map((section, index) => (
              <Box key={index} mb={2.5}>
                <KNTypography variant="p6" color="primary.light" sx={{paddingLeft: '16px', paddingRight: '4px'}}>
                  {menuExtended && t(section.sectionName)}
                  {!menuExtended && (section.sectionShortName ? t(section.sectionShortName) : t(section.sectionName))}
                </KNTypography>
                {section.sectionOptions.map((sectionOption, index) => (
                  getSection(sectionOption, index)
                ))}
              </Box>
            ))}
          </Box>
          <Box
            sx={({ functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
              position: 'absolute',
              bottom: isMobile ? 0 : pxToRem(30),
              width: '100%',
            })}
          >
            <Box my={4.5} sx={{ textAlign: 'left' }}>
              {userMenuOptions.map((section, index) => (
                <Box key={index} mb={2.5}>
                  <KNTypography variant="p6" color="primary.light" sx={{paddingLeft: '16px'}}>
                    {menuExtended && t(section.sectionName)}
                    {!menuExtended && (section.sectionShortName ? t(section.sectionShortName) : t(section.sectionName))}
                  </KNTypography>
                  {section.sectionOptions.map((sectionOption, index) => (
                    getSection(sectionOption, index)
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                display: 'flex',
                justifyContent: menuExtended ? 'flex-end' : 'center',
                left: !menuExtended ? -5 : 0,
              }}
            >
              <IconButton
                onClick={(): void => handleDrawerMenuExtend()}
                data-test={'drawer-menu-extendButton'}
                color="primary"
                size={menuExtended ? 'small' : 'small'}
              >
                {menuExtended ? <KNHideMenuIcon /> : <KNRevealIcon sx={{ height: '20px' }} />}
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    )
    if (isMobile) {
      return (
        <Slide direction="left" in={isMobile && menuOpen} unmountOnExit>
          {drawerMenu}
        </Slide>
      )
    }
    return drawerMenu
  }

  return mobileWrapper()
}

export default DrawerMenu
