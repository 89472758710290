import React, { useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import { getFormattedUnit } from 'global/helpers/units'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverList from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverList'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import { KNADRIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { LegData } from './TripDetails.types'
import Temperature from './Temperature'

export interface LegHeaderProps {
  trip: TripData
  leg: LegData
  size?: 'large'
}

const LegHeader = ({ trip, leg, size }: LegHeaderProps): ReactElement => {
  const { t } = useTranslation()
  const hasGeneralDetails =
    leg.attributes.customerReferences.length > 0 || !!leg.collectionInstructions || !!leg.deliveryInstructions
  const hasCargoItemDetails = leg.attributes.cargoItemDetails.length > 0
  const hasShipmentDetails = hasGeneralDetails || hasCargoItemDetails
  const {
    anchorEl: shipmentDetailsAnchorEl,
    open: shipmentDetailsOpen,
    handleClick: handleShipmentDetailsClick,
    handleClose: handleShipmentDetailsClose,
  } = usePopoverState()
  const {
    anchorEl: dangerousDetailsAnchorEl,
    open: dangerousDetailsOpen,
    handleClick: handleDangerousDetailsClick,
    handleClose: handleDangerousDetailsClose,
  } = usePopoverState()

  const handleShipmentDetailsClickWrapper = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    handleShipmentDetailsClick(event)
    analyticsEvent('polestar_cs_leg_details_popover', [getRouteName(location.pathname)])
  }, [])

  const handleDangerousDetailsClickWrapper = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    handleDangerousDetailsClick(event)
    analyticsEvent('polestar_cs_leg_dangerous_goods_popover', [getRouteName(location.pathname)])
  }, [])

  return (
    <>
      <Stack data-test="leg-id-container" data-guide="leg-summary" spacing={1} direction="row" alignItems="center">
        <KNTypography color="text.main" variant={size === 'large' ? 'h6' : 'p2'}>
          {leg.shipmentNumber}
        </KNTypography>
        <Stack direction="row">
          {hasShipmentDetails && (
            <IconButton
              onClick={handleShipmentDetailsClickWrapper}
              size="small"
              color="primary"
              sx={{ margin: '-5px 0 !important' }}
            >
              <InfoOutlinedIcon data-test="info-icon" />
            </IconButton>
          )}
          {leg.attributes.dangerousGoodsDetails.length > 0 && (
            <IconButton
              onClick={handleDangerousDetailsClickWrapper}
              size="small"
              color="error"
              sx={{ margin: '-5px 0 !important' }}
            >
              <KNADRIcon data-test="dangerous-goods-icon" />
            </IconButton>
          )}
          {leg.pharmaExtendedInformation && <Temperature preloadedLegs={[leg]} trip={trip} />}
        </Stack>
      </Stack>

      {hasShipmentDetails && (
        <KNDetailsPopover
          open={shipmentDetailsOpen}
          onClose={handleShipmentDetailsClose}
          anchorEl={shipmentDetailsAnchorEl}
        >
          {hasGeneralDetails && (
            <KNDetailsPopoverList
              items={[
                ...(leg.attributes.customerReferences.length > 0
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.references'),
                        value: leg.attributes.customerReferences,
                        stacked: true,
                      },
                    ]
                  : []),
                ...(leg.attributes.pupReferences.length > 0
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.pickup_references'),
                        value: leg.attributes.pupReferences,
                        stacked: true,
                      },
                    ]
                  : []),
                ...(leg.attributes.delReferences.length > 0
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.delivery_references'),
                        value: leg.attributes.delReferences,
                        stacked: true,
                      },
                    ]
                  : []),
                ...(leg.collectionInstructions
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.collection_instructions'),
                        value: leg.collectionInstructions,
                        stacked: true,
                      },
                    ]
                  : []),
                ...(leg.deliveryInstructions
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.delivery_instructions'),
                        value: leg.deliveryInstructions,
                        stacked: true,
                      },
                    ]
                  : []),
              ]}
            />
          )}
          {leg.attributes.cargoItemDetails.map((details, index) => (
            <KNDetailsPopoverList
              key={index}
              description={details.description}
              items={[
                {
                  label: t('screens.cs.trip_details.shipment_details.packages'),
                  value: details.packagesType
                    ? `${details.numberOfPackages} × ${details.packagesType}`
                    : details.numberOfPackages,
                },
                ...(details.innerPackage
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.inner_packages'),
                        value: details.innerPackageType
                          ? `${details.innerPackage} × ${details.innerPackageType}`
                          : details.innerPackage,
                      },
                    ]
                  : []),
                ...(details.stackable
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.stackable'),
                      },
                    ]
                  : []),
                ...(details.marksAndNumbers
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.marks_numbers'),
                        value: details.marksAndNumbers,
                      },
                    ]
                  : []),
                ...(details.weight
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.weight'),
                        value: `${parseFloat(details.weight.toFixed(2))} ${getFormattedUnit(details.weightUnit)}`,
                      },
                    ]
                  : []),
                ...(details.volume
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.volume'),
                        value: `${parseFloat(details.volume.toFixed(2))} ${getFormattedUnit(details.volumeUnit)}`,
                      },
                    ]
                  : []),
                ...(details.size.length && details.size.width && details.size.height
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.dimensions'),
                        value: `${parseFloat(details.size.length.toFixed(2))} / ${parseFloat(
                          details.size.width.toFixed(2)
                        )} / ${parseFloat(details.size.height.toFixed(2))} ${getFormattedUnit(details.sizeUnit)}`,
                      },
                    ]
                  : []),
                ...(details.loadingMeters
                  ? [
                      {
                        label: t('screens.cs.trip_details.shipment_details.loading_meters'),
                        value: `${parseFloat(details.loadingMeters.toFixed(2))} ${getFormattedUnit(
                          details.loadingMetersUnit
                        )}`,
                      },
                    ]
                  : []),
              ]}
            />
          ))}
        </KNDetailsPopover>
      )}

      {leg.attributes.dangerousGoodsDetails.length > 0 && (
        <KNDetailsPopover
          open={dangerousDetailsOpen}
          onClose={handleDangerousDetailsClose}
          anchorEl={dangerousDetailsAnchorEl}
        >
          {leg.attributes.dangerousGoodsDetails.map((details, index) => (
            <KNDetailsPopoverList
              key={index}
              description={details.description}
              items={[
                {
                  label: t('screens.cs.trip_details.dangerous_goods.packages'),
                  value: details.packagesType
                    ? `${details.numberOfPackages} × ${details.packagesType}`
                    : details.numberOfPackages,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.un_number'),
                  value: details.unNumber,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.class'),
                  value: details.classType,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.packing_group'),
                  value: details.packingGroup,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.quantity'),
                  value: details.quantityType ? `${details.quantity} × ${details.quantityType}` : details.quantity,
                },
              ]}
            />
          ))}
        </KNDetailsPopover>
      )}
    </>
  )
}

export default LegHeader
