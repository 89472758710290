import borders from 'assets/theme/base/borders'
import boxShadows from 'assets/theme/base/boxShadows'
import typography from 'assets/theme/base/typography'
import colors from 'assets/theme/base/colors'
import pxToRem from 'assets/theme/functions/pxToRem'

// types
type Types = any

const { size, fontWeightRegular, lineHeight } = typography
const { borderRadius, borderWidth } = borders
const { primary, grey } = colors
const { standardBoxShadow } = boxShadows

const datePicker: Types = {
  '.react-datepicker': {
    display: 'flex',
    fontFamily: 'inherit',
    boxShadow: standardBoxShadow,
    border: 'none',
    padding: '0.5rem',

    '&-wrapper': {
      width: '100%',
    },

    '&__header': {
      paddingBottom: 0,
      border: 'none',
      backgroundColor: 'inherit',
    },

    '&-popper': {
      zIndex: 9,
      borderRadius: borderRadius.xs,
      padding: '0 !important',
    },

    '&__day, &__day-name': {
      fontSize: size.sm,
      fontWeight: fontWeightRegular,
      lineHeight: lineHeight.md,
      borderRadius: borderRadius.round,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: pxToRem(36),
      height: pxToRem(36),
    },

    '&__day': {
      '&-name': {
        color: grey[400],
      },

      '&--outside-month': {
        visibility: 'hidden',
      },
      '&:hover': {
        borderRadius: borderRadius.round,
        backgroundColor: grey[200],
      },

      '&--today': {
        border: `${borderWidth[1]} solid ${grey[500]}`,
        backgroundColor: 'inherit',
        '&:hover': {
          backgroundColor: grey[200],
        },
      },

      '&--selected': {
        backgroundColor: primary.main,
        '&:hover': {
          backgroundColor: primary.dark,
        },
      },

      '&--keyboard-selected': {
        backgroundColor: 'inherit',
      },
    },
    '&__triangle': {
      display: 'none',
    },
  },
}

export default datePicker
