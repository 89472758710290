import { useState, ReactElement } from 'react'
import { DayPicker, DateRange, Modifiers, isDateRange, rangeIncludesDate } from 'react-day-picker'
import { isEqual, isAfter, addDays, subDays, startOfWeek, endOfWeek, startOfDay, endOfDay } from 'date-fns'
import { useTranslation } from 'react-i18next'

// @mui imports
import { styled } from '@mui/material/styles'
import InputAdornment from '@mui/material/InputAdornment'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import { DateField } from '@mui/x-date-pickers/DateField'

// KN imports
import { zonedDate, getDateLocale } from 'global/helpers/dateFormatters'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

const StyledDayPicker = styled(DayPicker)(({ theme }) => ({
  position: 'relative',
  '.rdp-months': {
    display: 'flex',
    gap: '1rem',
  },
  '.rdp-month_caption': {
    ...theme.typography['p4'],
    color: theme.palette.primary.main,
    lineHeight: '2rem',
  },
  '.rdp-nav': {
    position: 'absolute',
    top: 0,
    right: '-0.5rem',
  },
  '.rdp-button_previous, .rdp-button_next': {
    appearance: 'none',
    background: 'none',
    border: 'none',
    verticalAlign: 'top',
    cursor: 'pointer',
    width: '2rem',
    height: '2rem',
    '.rdp-chevron': {
      width: '1rem',
      height: '1rem',
      verticalAlign: 'top',
      fill: theme.palette.primary.light,
    },
    '&:hover .rdp-chevron': {
      fill: theme.palette.primary.main,
    },
    '&[disabled]': {
      visibility: 'hidden',
    },
  },
  '.rdp-month_grid': {
    margin: '0 auto',
    borderCollapse: 'collapse',
  },
  '.rdp-weekdays': {
    height: '2rem',
  },
  '.rdp-weekday': {
    ...theme.typography['p6'],
    color: theme.palette.primary.light,
  },
  '.rdp-day': {
    position: 'relative',
    ...theme.typography['p5'],
    '.rdp-day_button': {
      position: 'relative',
      display: 'block',
      appearance: 'none',
      border: 'none',
      background: 'none',
      color: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      width: '2.5rem',
      height: '2rem',
      textAlign: 'center',
      cursor: 'pointer',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '2px',
      right: 0,
      bottom: '2px',
      left: 0,
    },
    '&.rdp-today': {
      color: theme.palette.secondary.main,
      ...theme.typography['p6'],
    },
    '&.rdp-outside, &.rdp-disabled': {
      color: theme.palette.primary.light,
    },
    '&.rdp-range_start, &.rdp-range_middle, &.rdp-range_end': {
      ...theme.typography['p6'],
      '&:before': {
        background: theme.palette.secondary.lighter,
      },
    },
    '&.rdp-preview_start, &.rdp-preview_middle, &.rdp-preview_end': {
      color: theme.palette.common.white,
      ...theme.typography['p6'],
      '&:before': {
        background: theme.palette.secondary.main,
      },
    },
    '&.rdp-range_start, &.rdp-preview_start, &.rdp-preview_end + .rdp-range_middle, &.rdp-preview_end + .rdp-range_end': {
      '&:before': {
        borderTopLeftRadius: theme.borders.borderRadius.lg,
        borderBottomLeftRadius: theme.borders.borderRadius.lg,
      },
    },
    '&.rdp-range_end, &.rdp-preview_end, &.rdp-range_middle:has(+.rdp-preview_start)': {
      '&:before': {
        borderTopRightRadius: theme.borders.borderRadius.lg,
        borderBottomRightRadius: theme.borders.borderRadius.lg,
      },
    },
    '&.rdp-range_start.rdp-preview_middle, &.rdp-range_end.rdp-preview_middle': {
      '&:before': {
        borderRadius: 0,
      },
    },
    '&.rdp-range_start.rdp-preview_end': {
      '&:before': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    '&.rdp-range_end.rdp-preview_start': {
      '&:before': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    '&.rdp-preview_start.rdp-preview_end': {
      '&:before': {
        borderRadius: theme.borders.borderRadius.lg,
      },
    },
  },
}))

interface KNDatePickerProps {
  value?: DateRange
  minDate: Date
  maxDate: Date
  labelFrom?: string
  labelTo?: string
  placeholderFrom?: string
  placeholderTo?: string
  onChange: (value?: DateRange) => void
}

const KNDatePicker = ({
  value,
  minDate,
  maxDate,
  labelFrom,
  labelTo,
  placeholderFrom,
  placeholderTo,
  onChange,
}: KNDatePickerProps): ReactElement => {
  const { t } = useTranslation()

  const today = new Date()
  const lastWeek = { from: startOfDay(subDays(today, 6)), to: today }
  const currentWeek = { from: startOfWeek(today, { locale: getDateLocale() }), to: endOfWeek(today, { locale: getDateLocale() }) }
  const nextWeek = { from: today, to: endOfDay(addDays(today, 6)) }

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [month, setMonth] = useState(today)
  const [preview, setPreview] = useState<DateRange>()
  const [currentSelection, setCurrentSelection] = useState<'from' | 'to'>('from')

  const handleFromClick = (event: React.MouseEvent<HTMLElement>) => {
    setPreview(undefined)
    setCurrentSelection('from')
    setAnchorEl(event.currentTarget)
  }

  const handleToClick = (event: React.MouseEvent<HTMLElement>) => {
    setPreview(undefined)
    setCurrentSelection('to')
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setPreview(undefined)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Stack spacing={2} direction="row" data-test="date-calendar">
        <DateField
          data-test="date-from"
          fullWidth
          value={value?.from}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(newValue, context) => {
            if (!context.validationError) {
              onChange({ from: newValue ?? undefined, to: value?.to })
            }
          }}
          label={labelFrom}
          InputProps={{
            placeholder: placeholderFrom,
            endAdornment: (
              <InputAdornment position="end">
                <CalendarMonthIcon sx={{ color: 'primary.light' }} />
              </InputAdornment>
            ),
          }}
          onClick={handleFromClick}
        />
        <DateField
          data-test="date-to"
          fullWidth
          value={value?.to}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(newValue, context) => {
            if (!context.validationError) {
              onChange({ from: value?.from, to: newValue ?? undefined })
            }
          }}
          label={labelTo}
          InputProps={{
            placeholder: placeholderTo,
            endAdornment: (
              <InputAdornment position="end">
                <CalendarMonthIcon sx={{ color: 'primary.light' }} />
              </InputAdornment>
            ),
          }}
          onClick={handleToClick}
        />
      </Stack>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus
        sx={{
          marginTop: 0.5,
        }}
      >
        <Box sx={{ padding: 2, paddingTop: 1 }}>
          <StyledDayPicker
            locale={getDateLocale()}
            mode="range"
            month={month}
            onMonthChange={setMonth}
            selected={value}
            onSelect={onChange}
            startMonth={minDate ?? undefined}
            endMonth={maxDate ?? undefined}
            disabled={{
              ...minDate && { before: minDate },
              ...maxDate && { after: maxDate },
            }}
            modifiers={{
              selected: value,
              preview_start: preview?.from,
              preview_middle: (date: Date) => preview ? rangeIncludesDate(preview, date, true) : false,
              preview_end: preview?.to,
            }}
            modifiersClassNames={{
              preview_start: 'rdp-preview_start',
              preview_middle: 'rdp-preview_middle',
              preview_end: 'rdp-preview_end',
            }}
            onDayMouseEnter={(day: Date, modifiers: Modifiers, event: React.SyntheticEvent) => {
              if (!value) {
                return
              }
              if (value.from && value.to) {
                if (isEqual(day, value.from) || isAfter(day, value.from)) {
                  setPreview({ from: value.from, to: day })
                } else {
                  setPreview({ from: day, to: value.to })
                }
              } else {
                setPreview({ from: day, to: day })
              }
            }}
            onDayMouseLeave={(day: Date, modifiers: Modifiers, event: React.SyntheticEvent) => {
              setPreview(undefined)
            }}
            onDayClick={(day: Date, modifiers: Modifiers, event: React.SyntheticEvent) => {
              if (!value) {
                return
              }
              if (value.from && value.to) {
                handleClose()
              }
            }}
            footer={(
              <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
                <KNButton
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    onChange(lastWeek)
                    handleClose()
                  }}
                  onMouseOver={() => {
                    setMonth(today)
                    setPreview(lastWeek)
                  }}
                  onMouseOut={() => {
                    setPreview(undefined)
                  }}
                  sx={{
                    paddingY: 0,
                    paddingX: 1,
                  }}
                >
                  {t('form.last_7_days')}
                </KNButton>
                <KNButton
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    onChange(currentWeek)
                    handleClose()
                  }}
                  onMouseOver={() => {
                    setMonth(today)
                    setPreview(currentWeek)
                  }}
                  onMouseOut={() => {
                    setPreview(undefined)
                  }}
                  sx={{
                    paddingY: 0,
                    paddingX: 1,
                  }}
                >
                  {t('form.current_week')}
                </KNButton>
                <KNButton
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    onChange(nextWeek)
                    handleClose()
                  }}
                  onMouseOver={() => {
                    setMonth(today)
                    setPreview(nextWeek)
                  }}
                  onMouseOut={() => {
                    setPreview(undefined)
                  }}
                  sx={{
                    paddingY: 0,
                    paddingX: 1,
                  }}
                >
                  {t('form.next_7_days')}
                </KNButton>
              </Stack>
            )}
          />
        </Box>
      </Popover>
    </>
  )
}

export default KNDatePicker
