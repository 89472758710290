import colors from 'assets/theme/base/colors'
import boxShadows from 'assets/theme/base/boxShadows'
import borders from 'assets/theme/base/borders'

const { white, primary } = colors
const { lg } = boxShadows
const { borderRadius } = borders

// types
type Types = any

const popover: Types = {
  styleOverrides: {
    paper: {
      border: `1px solid ${primary.contrastText}`,
      backgroundColor: white.main,
      boxShadow: lg,
      borderRadius: borderRadius.md,
    },
  },
}

export default popover
