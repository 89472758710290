import { cloneElement, ReactElement } from 'react'

// @mui imports
import Stack from '@mui/material/Stack'

// KN imports
import { getFormattedUnit } from 'global/helpers/units'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types
import { KNAttributeProps } from './types'

const KNAttribute = ({ name, value, suffix, icon, hideEmpty }: KNAttributeProps): ReactElement | null => {
  const hasValue = value && (typeof value === 'number' ? value > 0 : true)
  if (!hasValue && hideEmpty) {
    return null
  }
  return (
    <Stack spacing={0.5} direction="row" alignItems="center" data-test={name ? `attribute-${name}` : undefined}>
      {icon &&
        cloneElement(icon, {
          sx: {
            color: 'primary.light',
            fontSize: '1rem',
          },
        })}
      {hasValue ? (
        <KNTypography color="primary.dark" variant="p5">
          {typeof value === 'number' ? parseFloat(value.toFixed(2)) : value}
          {suffix && <small>&nbsp;{getFormattedUnit(suffix)}</small>}
        </KNTypography>
      ) : (
        <KNTypography color="primary.light" variant="p5">
          —
        </KNTypography>
      )}
    </Stack>
  )
}

export default KNAttribute
