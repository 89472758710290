import colors from 'assets/theme/base/colors'

const { primary } = colors

// types
type Types = any

const accordion: Types = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      '&:before': {
        display: 'none',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        left: '2%',
        bottom: 0,
        height: 1,
        width: '100%',
        borderBottom: `1px solid ${primary.contrastText}`,
      },
    },
  },
}

export default accordion
