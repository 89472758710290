// KN base
import colors from './colors'

// KN helper functions
import pxToRem from '../functions/pxToRem'

const { dark } = colors

// types
interface Types {
  fontFamily: string
  fontWeightRegular: number
  fontWeightSemiBold: number
  fontWeightBold: number

  h2: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  h3: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: string | number
  }
  h4: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  h6: {
    fontFamily: string
    fontSize: string
    fontWeight: number
    color: string
    lineHeight: number
  }
  p1: {
    fontFamily: string,
    fontSize: string,
    lineHeight: number,
    fontWeight: number,
    textTransform: string,
  }
  p2: {
    fontFamily: string,
    fontSize: string,
    lineHeight: number,
    fontWeight: number,
    textTransform: string,
  }
  p3: {
    fontFamily: string,
    fontSize: string,
    lineHeight: string,
    fontWeight: number,
    textTransform: string,
  }
  p4: {
    fontFamily: string,
    fontSize: string,
    lineHeight: string,
    fontWeight: number,
    textTransform: string,
  }
  p5: {
    fontFamily: string,
    fontSize: string,
    lineHeight: string,
    fontWeight: number,
    textTransform: string,
  }
  p6: {
    fontFamily: string,
    fontSize: string,
    lineHeight: string,
    fontWeight: number,
    textTransform: string,
  }
  button: {
    fontFamily: string,
    fontSize: string,
    lineHeight: number,
    fontWeight: number,
    textTransform: any,
  }
  button2: {
    fontFamily: string,
    fontSize: string,
    lineHeight: number,
    fontWeight: number,
    textTransform: any,
  }
  caption: {
    fontFamily: string,
    fontSize: string,
    lineHeight: number,
    fontWeight: number,
  }

  size: {
    sm: string
    md: string
    lg: string
  }

  lineHeight: {
    sm: number
    md: number
    lg: number
  }
}

const baseProperties = {
  fontFamily: '"SuisseIntl", "Helvetica", "Arial", sans-serif',
  fontWeightRegular: 400,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontSizeSM: pxToRem(12),
  fontSizeMD: pxToRem(14),
  fontSizeLG: pxToRem(16),
}

const baseHeadingProperties = {
  fontFamily: baseProperties.fontFamily,
  color: dark.main,
  fontWeight: baseProperties.fontWeightBold,
}

const typography: Types = {
  fontFamily: baseProperties.fontFamily,
  fontWeightRegular: baseProperties.fontWeightRegular,
  fontWeightSemiBold: baseProperties.fontWeightSemiBold,
  fontWeightBold: baseProperties.fontWeightBold,

  h2: {
    fontSize: pxToRem(32),
    lineHeight: 1.25,
    ...baseHeadingProperties,
  },

  h3: {
    fontSize: pxToRem(30),
    lineHeight: 1.28571,
    ...baseHeadingProperties,
  },

  h4: {
    fontSize: pxToRem(24),
    lineHeight: 1.33333,
    ...baseHeadingProperties,
  },

  h6: {
    fontSize: pxToRem(18),
    lineHeight: 1.22222,
    ...baseHeadingProperties,
  },

  p1: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    lineHeight: 1.25,
    fontWeight: baseProperties.fontWeightRegular,
    textTransform: 'none',
  },

  p2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeLG,
    lineHeight: 1.25,
    fontWeight: baseProperties.fontWeightSemiBold,
    textTransform: 'none',
  },

  p3: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    lineHeight: pxToRem(20),
    fontWeight: baseProperties.fontWeightRegular,
    textTransform: 'none',
  },

  p4: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    lineHeight: pxToRem(20),
    fontWeight: baseProperties.fontWeightBold,
    textTransform: 'none',
  },

  p5: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    lineHeight: pxToRem(16),
    fontWeight: baseProperties.fontWeightRegular,
    textTransform: 'none',
  },

  p6: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    lineHeight: pxToRem(16),
    fontWeight: baseProperties.fontWeightSemiBold,
    textTransform: 'none',
  },

  button: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeMD,
    lineHeight: 1.28571,
    fontWeight: baseProperties.fontWeightSemiBold,
    textTransform: 'none',
  },

  button2: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    lineHeight: 1.16667,
    fontWeight: baseProperties.fontWeightSemiBold,
    textTransform: 'none',
  },

  caption: {
    fontFamily: baseProperties.fontFamily,
    fontSize: baseProperties.fontSizeSM,
    lineHeight: 1.16667,
    fontWeight: baseProperties.fontWeightRegular,
  },

  size: {
    sm: baseProperties.fontSizeSM,
    md: baseProperties.fontSizeMD,
    lg: baseProperties.fontSizeLG,
  },

  lineHeight: {
    sm: 1.25,
    md: 1.5,
    lg: 2,
  },
}

export default typography
