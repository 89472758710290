import { useState, useEffect, useCallback, useMemo, useRef, ReactElement } from 'react'
import i18n from 'i18n'
import { throttle } from 'lodash'

// Map imports
import {
  Map,
  MapRef,
  FullscreenControl,
  NavigationControl,
  ScaleControl,
  Source,
  Layer,
  Marker,
  MapLayerMouseEvent,
} from 'react-map-gl/maplibre'
import 'maplibre-gl/dist/maplibre-gl.css'
import { circle } from '@turf/circle'
import { point, lineString, featureCollection } from '@turf/helpers'
import { nearestPoint } from '@turf/nearest-point'
import { bbox } from '@turf/bbox'
import { Point, Feature, FeatureCollection, GeoJsonProperties } from 'geojson'

// KN imports
import { relativeDate } from 'global/helpers/dateFormatters'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNMapLibreMarker from './KNMapLibreMarker'
import { KNMapLibreProps, MapMarker } from './types'
import {
  getMarkerTypeColor,
  getRoutesLayer,
  getGeofencesLayer,
  getHeadingDate,
} from './helpers'
import KNMapLibreTooltip from './KNMapLibreTooltip'
import { getEstimatedSpeedLabel, getEstimatedSpeedColor } from 'screens/TripDetails/TripDetails.helpers'

const getHeadingTooltip = (properties: GeoJsonProperties): ReactElement | null => {
  if (!properties) {
    return null
  }
  const estimatedSpeedLabel = getEstimatedSpeedLabel(properties.estimatedSpeed)
  return (
    <>
      <KNTypography variant="p3">{relativeDate(properties.lastTimestamp || properties.timestamp)}</KNTypography>
      <KNTypography component="p" variant="p5">{getHeadingDate(properties.timestamp, properties.lastTimestamp)}</KNTypography>
      {(properties.estimatedSpeed ?? 0) > 0 && (
        <KNTypography component="p" variant="p5" color="primary.light">{i18n.t(`screens.cs.trip_details.map.${estimatedSpeedLabel}`)}</KNTypography>
      )}
    </>
  )
}

const KNMapLibre = <T extends object>({
  markers,
  geoPoints,
  groupedGeoPoints,
  withHeading = false,
  onMarkerClick,
  center,
  zoom = 10,
}: KNMapLibreProps): ReactElement | null => {
  const mapRef = useRef<MapRef>(null)
  const [metadata, setMetadata] = useState<FeatureCollection>()
  const [routes, setRoutes] = useState<FeatureCollection>()
  const [geofences, setGeofences] = useState<FeatureCollection>()
  const [tooltipMarkers, setTooltipMarkers] = useState<MapMarker[]>([])
  const [headingMarker, setHeadingMarker] = useState<MapMarker>()

  useEffect(() => {
    const markersMeta = markers.map((marker) => point(
      [
        marker.longitude,
        marker.latitude,
      ], {
        class: 'marker',
        id: marker.id,
        type: marker.type,
      } as GeoJsonProperties
    ))

    const routesMeta = geoPoints?.map((geoPoint) => point(
      [
        geoPoint.longitude,
        geoPoint.latitude,
      ],
      {
        class: 'route',
        ...geoPoint,
      } as GeoJsonProperties
    )) ?? []

    setMetadata(featureCollection([
      ...markersMeta,
      ...routesMeta,
    ]))

    const routesFeatureCollection = featureCollection(
      groupedGeoPoints?.filter((group) => group.geoPoints.length > 1).map((group) =>
        lineString(
          group.geoPoints.map((geoPoint) => [ geoPoint.longitude, geoPoint.latitude ]),
          {
            speed: group.label,
            color: getEstimatedSpeedColor(group.label),
          }
        )
      ) ?? []
    )
    setRoutes(routesFeatureCollection)

    const geofencesFeatureCollection = featureCollection(
      markers.filter((marker) => marker.geofence !== undefined).map((marker) => circle(
        [
          marker.longitude,
          marker.latitude,
        ],
        marker.geofence! / 1000,
        {
          steps: 64,
          properties: {
            id: marker.id,
            class: 'geofence',
            color: marker.color ?? getMarkerTypeColor(marker.type),
          },
        }
      ))
    )
    setGeofences(geofencesFeatureCollection)
  }, [markers, mapRef])

  const handleLoad = useCallback(() => {
    if (!metadata) {
      return
    }
    const boundingBox = bbox(metadata)
    mapRef.current?.fitBounds(
      [ [ boundingBox[0], boundingBox[1] ], [ boundingBox[2], boundingBox[3] ] ],
      {
        maxZoom: 16,
        padding: 64,
      }
    )
  }, [metadata, mapRef])

  const handleMouseMove = useCallback((event: MapLayerMouseEvent) => {
    if (!metadata) {
      return
    }

    // get all features inside a bbox around the mouse pointer
    const THRESHOLD = 16
    const features = event.target.queryRenderedFeatures(
      [
        [event.point.x - THRESHOLD, event.point.y - THRESHOLD],
        [event.point.x + THRESHOLD, event.point.y + THRESHOLD]
      ],
      {
        layers: ['metadata'],
      }
    )

    const markerFeatures = features.filter((feature) => feature.properties.class === 'marker') as Feature<Point, GeoJsonProperties>[]
    const markerIds = markerFeatures.map((feature) => feature.properties?.id)
    const intermediateTooltipMarkers = markers.filter((marker) => markerIds.includes(marker.id) && marker.tooltip)

    if (withHeading) {
      const routeFeatures = features.filter((feature) => feature.properties.class === 'route') as Feature<Point, GeoJsonProperties>[]
      if (routeFeatures.length > 0) {
        const nearest = nearestPoint([event.lngLat.lng, event.lngLat.lat], featureCollection(routeFeatures))
        const intermediateHeadingMarker = {
          id: 'heading',
          latitude: nearest.geometry.coordinates[1],
          longitude: nearest.geometry.coordinates[0],
          type: 'HEADING',
          color: getEstimatedSpeedColor(getEstimatedSpeedLabel(nearest.properties.estimatedSpeed)),
          heading: nearest.properties.heading,
          tooltip: getHeadingTooltip(nearest.properties) ?? undefined,
        }
        intermediateTooltipMarkers.push(intermediateHeadingMarker)
        setHeadingMarker(intermediateHeadingMarker)
      } else {
        setHeadingMarker(undefined)
      }

      setTooltipMarkers(intermediateTooltipMarkers)
    }
  }, [markers, metadata])
  const handleMouseMoveThrottled = useMemo(() => throttle(handleMouseMove, 100, { leading: true, trailing: false }), [handleMouseMove, markers, metadata])

  return (
    <Map
      ref={mapRef}
      mapStyle={`${process.env.PUBLIC_URL}/map.json`}
      onLoad={handleLoad}
      onMouseMove={handleMouseMoveThrottled}
      dragRotate={false}
      initialViewState={{
        zoom: 3,
      }}
    >
      <FullscreenControl position="top-left" />
      <NavigationControl position="top-left" />
      <ScaleControl />

      {metadata && (
        <Source id="metadata" type="geojson" data={metadata}>
          <Layer
            id="metadata"
            type="circle"
            paint={{
              'circle-opacity': 0,
            }}
          />
        </Source>
      )}

      {geofences && (
        <Source id="geofences" type="geojson" data={geofences} />
      )}
      <Layer {...getGeofencesLayer(true)} />
      <Layer {...getGeofencesLayer()} />

      {routes && (
        <Source id="routes" type="geojson" data={routes} />
      )}
      <Layer beforeId="label_other" {...getRoutesLayer('vehicle_fast')} />
      <Layer beforeId="label_other" {...getRoutesLayer('vehicle_medium')} />
      <Layer beforeId="label_other" {...getRoutesLayer('vehicle_slow')} />
      <Layer beforeId="label_other" {...getRoutesLayer('interruption')} />

      {markers.map((marker) => (
        <KNMapLibreMarker key={marker.id} marker={marker} onMarkerClick={onMarkerClick} />
      ))}

      {headingMarker && (
        <KNMapLibreMarker key={headingMarker.id} marker={headingMarker} />
      )}

      {tooltipMarkers.length > 0 && (
        <KNMapLibreTooltip markers={tooltipMarkers} />
      )}
    </Map>
  )
}

export default KNMapLibre
