import { useState, useEffect, useContext, useMemo, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import { SxProps } from '@mui/system'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

// KN Components
import { analyticsEvent } from 'global/helpers/analytics'
import { zonedDate } from 'global/helpers/dateFormatters'
import { TripListContext } from 'context/trips/TripListContext'
import { KNPickupPastDueIcon, KNDeliveryPastDueIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import PredefinedFiltersItem from './PredefinedFiltersItem'
import { CountersData } from './TripDashboard.types'
import Box from '@mui/material/Box'

export interface PredefinedFiltersProps {
  counters: CountersData
  sx?: SxProps
}

const PredefinedFilters = ({ counters, sx }: PredefinedFiltersProps): ReactElement => {
  const { t } = useTranslation()
  const [tripListState, tripListDispatch] = useContext(TripListContext)

  const filters = useMemo(
    () => [
      {
        name: 'PICKUP_EXPECTED',
        label: t('screens.cs.trip_dashboard.predefined_filters.pickup_expected'),
        icon: <FileUploadIcon />,
        tooltip: t('screens.cs.trip_dashboard.predefined_filters.pickup_expected_tooltip'),
      },
      {
        name: 'DELIVERY_EXPECTED',
        label: t('screens.cs.trip_dashboard.predefined_filters.delivery_expected'),
        icon: <FileDownloadIcon />,
        tooltip: t('screens.cs.trip_dashboard.predefined_filters.delivery_expected_tooltip'),
      },
      {
        name: 'PICKUP_PAST_DUE',
        label: t('screens.cs.trip_dashboard.predefined_filters.pickup_past_due'),
        icon: <KNPickupPastDueIcon />,
        tooltip: t('screens.cs.trip_dashboard.predefined_filters.pickup_past_due_tooltip'),
      },
      {
        name: 'DELIVERY_PAST_DUE',
        label: t('screens.cs.trip_dashboard.predefined_filters.delivery_past_due'),
        icon: <KNDeliveryPastDueIcon />,
        tooltip: t('screens.cs.trip_dashboard.predefined_filters.delivery_past_due_tooltip'),
      },
      {
        name: 'POD_MISSING',
        label: t('screens.cs.trip_dashboard.predefined_filters.pod_missing'),
        icon: <InsertDriveFileOutlinedIcon />,
        tooltip: t('screens.cs.trip_dashboard.predefined_filters.pod_missing_tooltip'),
      },
      {
        name: 'VEHICLE_MISSING',
        label: t('screens.cs.trip_dashboard.predefined_filters.vehicle_missing'),
        icon: <LocalShippingOutlinedIcon />,
        tooltip: t('screens.cs.trip_dashboard.predefined_filters.vehicle_missing_tooltip'),
      },
    ],
    []
  )

  return (
    <Box
      data-test="predefined-filters"
      data-guide="predefined-filters"
      sx={{
        padding: 1,
        ...sx,
      }}
    >
      <Grid container spacing={{ xs: 1, md: 2 }}>
        {filters.map((filter) => (
          <Grid item xs={6} sm={4} md={2} key={filter.name} >
            <PredefinedFiltersItem
              label={filter.label}
              icon={filter.icon}
              counter={counters[filter.name]}
              tooltip={filter.tooltip}
              active={tripListState.predefinedFilter === filter.name}
              onClick={() => {
                tripListDispatch({ type: 'setPredefinedFilter', payload: filter.name })
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default PredefinedFilters
