import { ReactElement, useState } from 'react'

// @mui imports //
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// KN components //
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormDropdown from 'components/KN_Molecules/KNForm/KNFormDropdown'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNFormRadioGroup from 'components/KN_Molecules/KNForm/KNFormRadioGroup'

// Modules //
import CreateCompanyDialog from './CreateCompanyDialog'

// Functional //
import { getCompanyOptions, getCompanyVisibilityLevels } from './PlaygroundForms.helpers'

// Data //
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types //
import { FormCompanyProps, CompanyProps } from 'screens/Playground/Playground.types'

const FormCompany = ({ control, setValue, getValues, companies, scenario }: FormCompanyProps): ReactElement => {
  const [createCompanyDialogOpen, setCreateCompanyDialogOpen] = useState<boolean>(false)
  const [displayCreateCompanyContent, setDisplayCreateCompanyContent] = useState<boolean>(false)

  const { translation } = playgroundTranslations()

  return (
    <Stack spacing={3} mt={1} mb={3}>
      <KNTypography variant="p4">{translation.companyData}</KNTypography>
      <Box mt={1}>
        <KNTypography variant="p5" color="primary.light">
          {translation.companyChooseFromListText}
        </KNTypography>
        <Box mt={1}>
          <KNFormDropdown
            sx={{
              maxWidth: 'sm',
            }}
            name="company.name"
            label={translation.companyNameLabel}
            control={control}
            rules={{
              required: translation.required,
            }}
            options={getCompanyOptions(companies)}
            onSetValue={(event): void => {
              setValue('company.createCompany', false)
              const chosenCompany = companies.find((company) => company.displayName === event.target.value)
              !scenario && setValue('company.visibilityLevel', chosenCompany?.properties?.visibilityLevel)
            }}
          />
        </Box>

        <KNTypography variant="p5" color="primary.light">
          {translation.or}
        </KNTypography>
        <Box mt={1}>
          <KNButton
            color="primary"
            variant="contained"
            size="small"
            onClick={(): void => {
              setCreateCompanyDialogOpen(true)
            }}
          >
            {displayCreateCompanyContent ? translation.companyEditButton : translation.companyCreateButton}
          </KNButton>
        </Box>
      </Box>

      {displayCreateCompanyContent && (
        <>
          <Box mt={1}>
            <KNFormText
              name="company.name"
              label={translation.companyNameLabel}
              control={control}
              disabled
              sx={{
                maxWidth: 'sm',
              }}
              rules={{
                required: translation.required,
              }}
            />
          </Box>
          {!scenario && (
            <Box mt={1}>
              <KNFormRadioGroup
                name="company.visibilityLevel"
                label={translation.companyVisibilityLevelLabel}
                control={control}
                disabled
                rules={{
                  required: translation.required,
                }}
                options={getCompanyVisibilityLevels()}
              />
            </Box>
          )}
        </>
      )}

      {createCompanyDialogOpen && (
        <CreateCompanyDialog
          companyData={getValues('company')}
          dialogOpen={createCompanyDialogOpen}
          scenario={scenario}
          handleClose={(): void => {
            setCreateCompanyDialogOpen(false)
          }}
          handleSave={(data: CompanyProps): void => {
            setValue('company.name', data.name)
            !scenario && setValue('company.visibilityLevel', data.visibilityLevel)
            setValue('company.createCompany', true)
            setDisplayCreateCompanyContent(true)
          }}
        />
      )}
    </Stack>
  )
}

export default FormCompany
