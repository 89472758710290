import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

// KN Components
import { UserListContext } from 'context/users/UserListContext'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'

const UserFilters = (): ReactElement => {
  const { t } = useTranslation()
  const [userListState, userListDispatch] = useContext(UserListContext)
  const [payload, setPayload] = useState<object | null>(null)
  const [debouncedPayload] = useDebounce(payload, 100)

  useEffect(() => {
    if (debouncedPayload) {
      userListDispatch({
        type: 'setFilters',
        payload: debouncedPayload,
      })
    }
    setPayload(null)
  }, [debouncedPayload])

  const handleResetClick = useCallback((): void => {
    userListDispatch({ type: 'resetFilters' })
    analyticsEvent('polestar_reset_user_filters_button_clicked')
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid container spacing={2}>
        <Grid data-test="search-users-filter" item xs={12} sm={4}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={userListState.filters.keywords ?? []}
            onChange={(event, newValue): void => {
              setPayload({
                keywords: [...newValue] as string[],
              })
            }}
            renderTags={(tagValue, getTagProps): ReactElement[] =>
              tagValue.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip {...getTagProps({ index })} variant="outlined" size="small" label={option} />
              ))
            }
            renderInput={(params): ReactElement => (
              <TextField {...params} label={t('screens.shared.user_manager.filters.search')} />
            )}
          />
        </Grid>
      </Grid>
      {userListState.filtersDirty && (
        <KNButton
          size="small"
          variant="text"
          color="primary"
          data-test="reset-user-filters-button"
          onClick={handleResetClick}
          sx={{
            padding: 0,
            minHeight: 'auto',
          }}
        >
          {t('general.reset_filters')}
        </KNButton>
      )}
    </Box>
  )
}

export default UserFilters
