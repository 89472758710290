import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'

const {size, fontWeightSemiBold} = typography

const tableCell = {
  styleOverrides: {
    head: {
      fontSize: size.sm,
      fontWeight: fontWeightSemiBold,
      color: colors.primary.light,
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
      backgroundColor: colors.white.main,
    },
  },
}

export default tableCell
