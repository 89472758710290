import { useState, useEffect, useCallback, ReactElement, ElementType, useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { parseISO, isThisYear } from 'date-fns'
import i18n from 'i18n'

// @mui imports
import { styled, Theme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Collapse from '@mui/material/Collapse'
import Timeline from '@mui/lab/Timeline'
import { TripListContext } from 'context/trips/TripListContext'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector, { timelineConnectorClasses } from '@mui/lab/TimelineConnector'
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent'
import TimelineDot, { timelineDotClasses } from '@mui/lab/TimelineDot'
import { TransitionGroup } from 'react-transition-group'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { compactZonedDate } from 'global/helpers/dateFormatters'

// Types
import { TripLogData, GeofenceTripLogData, StatusTripLogData, LegData } from './TripDetails.types'
import { StopsViewContext } from '../../context/trips/StopsViewContext'
import theme from '../../assets/theme'
import { getTypeIcon } from './StopHeader'

export const TripTimeline = styled(Timeline)<{ component?: ElementType }>(({ theme }: { theme: Theme }) => ({
  '&': {
    padding: 0
  },
  [`& .${timelineItemClasses.root}`]: {
    minHeight: 'auto',
    '&:before': {
      flex: 0,
      padding: 0
    }
  },
  [`& .${timelineDotClasses.root}`]: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    marginTop: '4px',
    marginBottom: '4px'
  },
  [`& .${timelineConnectorClasses.root}`]: {
    backgroundColor: theme.palette.primary.main
  },
  [`& .${timelineContentClasses.root}`]: {
    padding: '4px 8px',
    margin: '-2px 8px 8px 8px',
    borderRadius: '4px',
    '& strong': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    }
  }
}))

const getTripLogLabel = (tripLog: TripLogData): string | ReactElement => {
  switch (tripLog.type) {
    case 'ARRIVED_AT_LOCATION':
    case 'DEPARTED_FROM_LOCATION': {
      const geofenceData: GeofenceTripLogData = tripLog.data as GeofenceTripLogData
      return <Trans
        i18nKey={`screens.cs.trip_details.events.${tripLog.type}`}
        values={{
          location: geofenceData.name
        }}
        components={{ strong: <strong /> }}
      />
    }
    case 'STATUS_UPDATED': {
      const statusData: StatusTripLogData = tripLog.data as StatusTripLogData
      const statusId: string = statusData.reasonCode ? `${statusData.statusCode}_${statusData.reasonCode}` : statusData.statusCode
      return <Trans
        i18nKey={`screens.cs.trip_details.events.${tripLog.type}`}
        values={{
          shipmentNumber: statusData.shipmentNumber,
          status: i18n.t(`waypoints.statuses.${statusId}`)
        }}
        components={{ strong: <strong /> }}
      />
    }
    default:
      return i18n.t(`screens.cs.trip_details.events.${tripLog.type}`)
  }
}

export interface TripLogsProps {
  tripLogs: TripLogData[]
  weblinkToken?: string
  stopsViewAvailable?: boolean
  legs: LegData[]
}

const TripLogs = ({ legs, tripLogs, weblinkToken, stopsViewAvailable }: TripLogsProps): ReactElement | null => {
  const { t } = useTranslation()
  const [visibleTripLogsData, setVisibleTripLogsData] = useState<TripLogData[]>([])
  const [hiddenTripLogsData, setHiddenTripLogsData] = useState<TripLogData[]>([])
  const [tripListState, tripListDispatch] = useContext(TripListContext)
  const [stopsViewState, stopsViewDispatch] = useContext(StopsViewContext)

  useEffect(() => {
    setVisibleTripLogsData(tripLogs.slice(0, 4))
    setHiddenTripLogsData(tripLogs.slice(4))
  }, [tripLogs])

  const handleShowClick = useCallback(() => {
    setVisibleTripLogsData(tripLogs.length !== visibleTripLogsData.length ? tripLogs : tripLogs.slice(0, 4))
  }, [tripLogs, visibleTripLogsData])

  const handleTimelineClick = (tripLog: TripLogData): void => {
    switch (tripLog.type) {
      case 'ARRIVED_AT_LOCATION':
      case 'DEPARTED_FROM_LOCATION':
      case 'STATUS_UPDATED': {
        if (tripLog.data?.geoPoint) {
          stopsViewDispatch({
            type: 'setStopGeoPoint',
            payload: tripLog.data.geoPoint
          })
        }
        tripListDispatch({
          type: 'setDetailsView',
          payload: stopsViewAvailable ? 'sequence_map' : 'locations_map'
        })
        break
      }
      default:
        break
    }
  }

  const PickupIcon = getTypeIcon('PUP')
  const DeliveryIcon = getTypeIcon('DEL')
  const CustomsIcon = getTypeIcon('CUS')

  const getTripIcon = (tripLog: TripLogData): ReactElement => {
    const { wayPointCid } = tripLog.data ?? {}
    const iconProps = { sx: { width: '1.25rem', height: '1.25rem', fill: theme.palette.primary.main } }
    for (const leg of legs) {
      for (const waypoint of leg.wayPoints) {
        if (waypoint.wayPointCid === wayPointCid) {
          switch (waypoint.type) {
            case 'PUP': {
              return <PickupIcon {...iconProps} />
            }
            case 'DEL': {
              return <DeliveryIcon {...iconProps} />
            }
            case 'CUS': {
              return <CustomsIcon {...iconProps} />
            }
            default: {
              break
            }
          }
        }
      }
    }
    return <TimelineDot {...iconProps} />
  }


  if (tripLogs.length === 0) {
    return null
  }

  return (
    <Paper
      data-test="trip-events-container"
      elevation={16}
      sx={{
        padding: 2,
        overflowY: 'auto'
      }}
    >
      <TripTimeline>
        <TransitionGroup>
          {visibleTripLogsData.map((log, index) => (
            <Collapse key={log.entityId}>
              <TimelineItem>
                <TimelineSeparator>
                  {getTripIcon(log)}
                  {(index !== tripLogs.length - 1) && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent
                  onClick={(): void => handleTimelineClick(log)}>
                  <KNTypography variant="p6" color="primary.light" component="p">
                    {compactZonedDate(log.createdAt, log.data?.offset)}
                  </KNTypography>
                  <KNTypography color="text.main" variant="p3">
                    {getTripLogLabel(log)}
                  </KNTypography>
                </TimelineContent>
              </TimelineItem>
            </Collapse>
          ))}
        </TransitionGroup>
        <Collapse in={hiddenTripLogsData.length > 0}>
          <KNButton variant="text" size="small" onClick={handleShowClick} sx={{ width: '100%' }}>
            {tripLogs.length !== visibleTripLogsData.length ? t('general.show_more_with_count', { count: hiddenTripLogsData.length }) : t('general.show_less')}
          </KNButton>
        </Collapse>
      </TripTimeline>
    </Paper>
  )
}

export default TripLogs
