import { ReactElement } from 'react'
import { renderToString } from 'react-dom/server'

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

import theme from 'assets/theme'
import { MapMarkerState } from 'components/KN_Molecules/KNMap/types'

const outlineColor = '#ffffff'
const strokeWidth = 2
const activeStrokeWidth = 4

interface MapIconProps {
  size?: number
  color: string
  label?: string
  heading?: number
  state?: MapMarkerState
}

const getForegroundPathProps = (color: string, state?: MapMarkerState) => {
  return {
    fill: color,
    fillOpacity: state === MapMarkerState.Muted ? 0.25 : 1,
  }
}

const getBackgroundPathProps = (color: string, state?: MapMarkerState) => {
  return {
    fill: outlineColor,
    stroke: state === MapMarkerState.Active ? color : outlineColor,
    strokeWidth: state === MapMarkerState.Active ? activeStrokeWidth : strokeWidth,
    strokeOpacity: state === MapMarkerState.Active ? 0.5 : 1,
  }
}

export const encodeIconToDataUrl = (icon: ReactElement): string =>
  `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(renderToString(icon))))}`

export const KNGenericMapIcon = ({ size, color, label, state }: MapIconProps): ReactElement => {
  const lines = label ? label.split('\n') : []
  const fontSize = 8
  const lineHeight = 8
  const textHeight = lines.length * lineHeight
  const topOffset = 5 + (24 - textHeight) / 2
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        {...getBackgroundPathProps(color, state)}
        d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
      />
      <path
        {...getForegroundPathProps(color, state)}
        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
      />
      {lines.map((line, index) => (
        <text key={index} fill={theme.palette.common.white} stroke={color} strokeWidth="3" paintOrder="stroke" textAnchor="middle" fontFamily="Arial" fontSize={fontSize} x="12" y={topOffset + index * lineHeight}>
          {line}
        </text>
      ))}
    </svg>
  )
}

export const KNGeofenceMapIcon = ({ size, color, state }: MapIconProps): ReactElement => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      {...getBackgroundPathProps(color, state)}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
    />
    <path
      {...getForegroundPathProps(color, state)}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Zm4.467 7.547a4.506 4.506 0 0 1-3.968 3.926l3.974-3.974-.006.048Zm-.399-2.472c.159.334.277.691.35 1.064l-5.279 5.279a4.472 4.472 0 0 1-1.064-.35l5.993-5.993ZM14.82 5.494c.26.21.497.448.705.71l-6.321 6.321a4.552 4.552 0 0 1-.71-.705l6.326-6.326Zm-1.924-.905a4.47 4.47 0 0 1 1.058.357l-6.008 6.008a4.516 4.516 0 0 1-.357-1.058l5.307-5.307Zm-1.347-.067L7.522 8.549a4.484 4.484 0 0 1 1.297-2.73 4.484 4.484 0 0 1 2.73-1.297Z"
    />
  </svg>
)

export const KNPickupMapIcon = ({ size, color, state }: MapIconProps): ReactElement => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      {...getBackgroundPathProps(color, state)}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
    />
    <path
      {...getForegroundPathProps(color, state)}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Zm-5 8 5-5 5 5h-3v4.043h-4V10H7Z"
    />
  </svg>
)

export const KNDeliveryMapIcon = ({ size, color, state }: MapIconProps): ReactElement => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      {...getBackgroundPathProps(color, state)}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
    />
    <path
      {...getForegroundPathProps(color, state)}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Zm5 7.043-5 5-5-5h3V5h4v4.043h3Z"
    />
  </svg>
)

export const KNCustomsMapIcon = ({ size, color, state }: MapIconProps): ReactElement => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      {...getBackgroundPathProps(color, state)}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
    />
    <path
      {...getForegroundPathProps(color, state)}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Zm.909 10.182h-2.727V14L7 10.818l3.182-3.182v1.819h2.727v2.727ZM13.818 4 17 7.182l-3.182 3.182V8.545h-2.727V5.818h2.727V4Z"
    />
  </svg>
)

export const KNVehicleMapIcon = ({ size, color, state }: MapIconProps): ReactElement => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      {...getBackgroundPathProps(color, state)}
      d="M20 7h-3V5H3c-1.1 0-2 .9-2 2v9h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4Z"
    />
    <path
      {...getForegroundPathProps(color, state)}
      d="M20 7h-3V5H3c-1.1 0-2 .9-2 2v9h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4ZM6 17.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5m13.5-9 1.96 2.5H17V8.5h2.5Zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5"
    />
  </svg>
)

export const KNHeadingIcon = ({ size, color, state }: MapIconProps): ReactElement => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      {...getBackgroundPathProps(color, state)}
      d="M12 5 6 19l6-2.473L18 19 12 5Z"
    />
    <path
      {...getForegroundPathProps(color, state)}
      d="M12 5 6 19l6-2.473L18 19 12 5Z"
    />
  </svg>
)

export const KNFirstPositionIcon = ({ size, color, state }: MapIconProps): ReactElement => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      {...getBackgroundPathProps(color, state)}
      d="M14.4 6 14 4H5v17h2v-7h5.6l.4 2h7V6z"
    />
    <path
      {...getForegroundPathProps(color, state)}
      d="M14.4 6 14 4H5v17h2v-7h5.6l.4 2h7V6z"
    />
  </svg>
)

export const KNDwellMapIcon = ({ size, color, state }: MapIconProps): ReactElement => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      {...getBackgroundPathProps(color, state)}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
    />
    <path
      {...getForegroundPathProps(color, state)}
      d="M12 2c3.84 0 7 3.16 7 7 0 5.25-7 13-7 13S5 14.25 5 9c0-3.84 3.16-7 7-7Zm0 2.5A4.502 4.502 0 0 0 7.5 9c0 2.484 2.016 4.5 4.5 4.5s4.5-2.016 4.5-4.5-2.016-4.5-4.5-4.5Zm1.65 6.85L11.5 9.2V6h1v2.79l1.85 1.85-.7.71Z"
    />
  </svg>
)

export const KNClusterMapIcon = ({ size, color, state }: MapIconProps): ReactElement => (
  <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill={color ?? theme.palette.primary.main} fillOpacity={state === MapMarkerState.Muted ? 0.15 : 0.25} />
    <circle cx="20" cy="20" r="16" fill={color ?? theme.palette.primary.main} fillOpacity={state === MapMarkerState.Muted ? 0.15 : 0.25} />
    <circle cx="20" cy="20" r="12" fill={color ?? theme.palette.primary.main} fillOpacity={state === MapMarkerState.Muted ? 0.25 : 1} />
  </svg>
)
