import { ReactElement, cloneElement } from 'react'

// @mui imports
import { Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'

// KN Components
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

export interface PredefinedFiltersItemProps {
  label: string
  icon: ReactElement
  counter: number
  tooltip: string
  active?: boolean
  onClick: () => void
}

const PredefinedFiltersItem = ({
  label,
  icon,
  counter,
  tooltip,
  active = false,
  onClick,
}: PredefinedFiltersItemProps): ReactElement => (
  <Tooltip title={tooltip} arrow enterDelay={1000}>
    <Stack direction="column" spacing={1} alignItems="center">
      <Box
        data-test="predefined-filter-item"
        sx={{
          cursor: 'pointer',
          borderRadius: '2rem',
          border: '2px solid',
          ...(active
            ? {
              backgroundColor: 'primary.light',
              borderColor: 'primary.light',
              '&:hover': {
                borderColor: 'primary.light',
              }
            }
            : {
              backgroundColor: 'white.main',
              borderColor: 'white.main',
              '&:hover': {
                borderColor: 'primary.light',
              }
            })
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            borderRadius: '2rem',
            border: '2px solid',
            minWidth: '6rem',
            ...(active
              ? {
                backgroundColor: 'primary.light',
                borderColor: 'primary.light',
                '& .MuiTypography-p6, & .MuiTypography-h4': {
                  color: 'white.main'
                },
                '&:hover': {
                  borderColor: 'white.main',
                }
              }
              : {
                backgroundColor: 'white.main',
                borderColor: 'white.main',
              })
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-around">
            {cloneElement(icon, {
              color: 'primary',
              fontSize: 'medium'
            })}
            <KNTypography data-test="counter-number" variant="h4" color="primary.main">
              {counter}
            </KNTypography>
          </Stack>
        </Box>
      </Box>
      <KNTypography variant="p6" color="primary.main" component="div" align="center">
        {label}
      </KNTypography>
    </Stack>
  </Tooltip>
)

export default PredefinedFiltersItem
