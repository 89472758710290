import { Reducer } from 'react'
import createStore from 'global/helpers/createStore'

export interface CarrierFiltersValues {
  keywords?: string[]
  integrations?: string[]
  telematics?: string[]
  features?: string[]
}

export interface CarrierListState {
  filters: CarrierFiltersValues
  filtersDirty: boolean
}

export type CarrierListAction = { type: 'setFilters'; payload: CarrierFiltersValues } | { type: 'resetFilters' }

const initialFilters: CarrierFiltersValues = {
  keywords: [],
  integrations: [],
  telematics: [],
  features: [],
}

const initialState: CarrierListState = {
  filters: initialFilters,
  filtersDirty: false,
}

const reducer = (state: CarrierListState, action: CarrierListAction): CarrierListState => {
  switch (action.type) {
    case 'setFilters':
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
        filtersDirty: JSON.stringify({ ...state.filters, ...action.payload }) !== JSON.stringify(initialFilters),
      }
    case 'resetFilters':
      return {
        ...state,
        filters: initialFilters,
        filtersDirty: false,
      }
    default:
      throw new Error()
  }
}

const { StoreContext, StoreProvider } = createStore(reducer, initialState)

export { StoreContext as CarrierListContext, StoreProvider as CarrierListProvider }
