import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// @mui imports
import Box from '@mui/material/Box'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import KNCountryFlag from 'components/KN_Molecules/KNCountryFlag/KNCountryFlag'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverMap from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverMap'
import { MapMarker } from 'components/KN_Molecules/KNMap/types'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

export interface LocationLinkProps {
  countryCode?: string
  city?: string
  zipCode?: string
  street?: string
  marker?: MapMarker
  lineBreaks?: 'all' | 'country' | 'city'
  hideMap?: boolean
}

const LocationLink = ({
  countryCode,
  city,
  zipCode,
  street,
  marker,
  lineBreaks,
  hideMap,
}: LocationLinkProps): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const {
    anchorEl: mapAnchorEl,
    open: mapOpen,
    handleClick: handleMapClick,
    handleClose: handleMapClose,
  } = usePopoverState()

  const handleLocationClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (hideMap) {
      return
    }
    handleMapClick(event)
    analyticsEvent('polestar_cs_leg_location_popover', [getRouteName(location.pathname)])
  }, [hideMap])

  return (
    <>
      <KNTypography color="text.main" variant="p3" component="p">
        <Box
          component="span"
          onClick={marker && handleLocationClick}
          sx={{
            cursor: marker ? 'pointer' : undefined,
          }}
        >
          {countryCode && (
            <>
              <KNCountryFlag countryCode={countryCode} />
              {t(`countries.${countryCode}`)}
            </>
          )}
          {city && (
            <>
              {countryCode && (lineBreaks === 'all' || lineBreaks === 'country' ? <br /> : ', ')}
              {[zipCode, city].filter((value) => value).join(', ')}
            </>
          )}
          {street && (
            <>
              {(countryCode || city) && (lineBreaks === 'all' || lineBreaks === 'city' ? <br /> : ', ')}
              {street}
            </>
          )}
        </Box>
      </KNTypography>
      {marker && (
        <KNDetailsPopover open={mapOpen} onClose={handleMapClose} anchorEl={mapAnchorEl}>
          <KNDetailsPopoverMap markers={[marker]} />
        </KNDetailsPopover>
      )}
    </>
  )
}

export default LocationLink
