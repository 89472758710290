import { ReactElement, memo } from 'react'
import { Link } from 'react-router-dom'

// @mui imports
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'

// KN components
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import TruckNorrisAccessDenied from './TruckNorris/TruckNorrisAccessDenied'

// Functional
import { getTruckNorrisQuote } from './Error.helpers'

const ErrorCV = (): ReactElement => {
  return (
    <Container maxWidth="sm">
      <Stack data-test="notfound-container" spacing={2} justifyContent="center" alignItems="center" mt={8}>
        <TruckNorrisAccessDenied />
        <KNTypography variant="h4">{getTruckNorrisQuote('access_denied')}</KNTypography>
        <KNTypography variant="h6" color="dark.main">
          {`It seems that you're trying to use our platform as a Shipment Insight user, please navigate to `}
          <a href="https://shipmentinsight.kuehne-nagel.com">https://shipmentinsight.kuehne-nagel.com</a>
        </KNTypography>
      </Stack>
    </Container>
  )
}

export default memo(ErrorCV)
