// @mui imports
import { createTheme } from '@mui/material/styles'

// KN base styles
import borders from './base/borders'
import shadows from './base/shadows'
import boxShadows from './base/boxShadows'
import colors from './base/colors'
import globals from './base/globals'
import typography from './base/typography'

// KN Components
import accordion from './components/accordion/index'
import accordionDetails from './components/accordion/accordionDetails'
import accordionSummary from './components/accordion/accordionSummary'
import button from './components/button/index'
import dateRangePicker from './components/daterangepicker/daterangepicker'
import datePicker from './components/datepicker/datepicker'
import dialog from './components/dialog'
import dialogActions from './components/dialog/dialogActions'
import dialogContent from './components/dialog/dialogContent'
import dialogTitle from './components/dialog/dialogTitle'
import link from './components/link/link'
import pagination from './components/pagination'
import paginationItem from './components/pagination/paginationItem'
import popover from './components/popover/popover'
import stepConnector from './components/stepper/stepConnector'
import stepLabel from './components/stepper/stepLabel'
import switchButton from './components/form/switchButton'
import tableCell from './components/table/tableCell'
import tableRow from './components/table/tableRow'
import tooltip from './components/tooltip/tooltip'

// KN functions
import boxShadow from './functions/boxShadow'
import hexToRgb from './functions/hexToRgb'
import linearGradient from './functions/linearGradient'
import pxToRem from './functions/pxToRem'
import rgba from './functions/rgba'

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920,
    },
  },
  typography: { ...typography },
  boxShadows: { ...boxShadows },
  palette: { ...colors },
  borders: { ...borders },
  shadows: { ...shadows },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...dateRangePicker,
        ...datePicker,
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiAccordion: { ...accordion },
    MuiAccordionDetails: { ...accordionDetails },
    MuiAccordionSummary: { ...accordionSummary },
    MuiButton: { ...button },
    MuiDialog: { ...dialog },
    MuiDialogActions: { ...dialogActions },
    MuiDialogContent: { ...dialogContent },
    MuiDialogTitle: { ...dialogTitle },
    MuiLink: { ...link },
    MuiPagination: { ...pagination },
    MuiPaginationItem: { ...paginationItem },
    MuiPopover: { ...popover },
    MuiStepConnector: { ...stepConnector },
    MuiStepLabel: { ...stepLabel },
    MuiSwitch: { ...switchButton },
    MuiTableCell: { ...tableCell },
    MuiTableRow: { ...tableRow },
    MuiTooltip: { ...tooltip },

    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          border: '2px solid',
          borderColor: theme.palette.darkblue[5],
          borderRadius: '1rem',
          backgroundColor: theme.palette.darkblue[5],
          padding: '3px 8px 4px 8px',
          '&.Mui-focused': {
            outline: '3px solid',
            outlineColor: theme.palette.darkblue[5],
            borderColor: theme.palette.primary.lighter,
          },
          'label + &': {
            marginTop: '0.125rem !important',
          },
          '& .MuiChip-root': {
            margin: '0 2px 0 0',
          },
        }),
        input: {
          padding: 0,
          '&:focus': {
            background: 'none',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '3px 8px 4px 8px',
          '& .MuiInput-input': {
            padding: 0,
            borderRadius: 0,
          },
        },
        tag: {
          margin: '0 2px 0 0',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          position: 'relative',
          transform: 'none',
          transition: 'none',
          fontSize: '0.75rem',
          fontWeight: 600,
          color: theme.palette.primary.light,
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          verticalAlign: 'top',
          ...(ownerState.variant === 'outlined' && {
            background: 'transparent',
            borderColor: theme.palette.darkblue[25],
          }),
          ...(ownerState.size === 'small' && {
            fontSize: '0.75rem',
            height: 23,
          }),
        }),
        label: ({ ownerState, theme }) => ({
          ...(ownerState.size === 'small' && {
            paddingLeft: 6,
            paddingRight: 6,
          }),
        }),
        deleteIcon: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'outlined' && {
            color: theme.palette.darkblue[25],
            '&:hover': {
              color: theme.palette.darkblue[50],
            },
          }),
        }),
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '0.875rem',
          '&:hover, &.Mui-selected:hover, &.Mui-focusVisible, &.Mui-selected.Mui-focusVisible': {
            backgroundColor: theme.palette.lightblue[10],
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.lightblue[5],
          },
        }),
      },
    },
  },
})
