import { useEffect, useState, useContext, useMemo, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import DeleteIcon from '@mui/icons-material/Delete'

// KN imports
import { zonedDate } from 'global/helpers/dateFormatters'
import { isSupportUser } from 'global/helpers/environment'
import { replaceHighlights } from 'global/helpers/highlights'
import { UserContext } from 'context/authentication/UserContext'
import { TripListContext } from 'context/trips/TripListContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import KNDataTableMenu from 'components/KN_Molecules/KNDataTable/KNDataTableMenu'
import KNDataTableOverflow from 'components/KN_Molecules/KNDataTable/KNDataTableOverflow'
import KNCountryFlag from 'components/KN_Molecules/KNCountryFlag/KNCountryFlag'
import TripCargo from './TripCargo'
import QuickStatusUpdateDialog from 'screens/StatusManager/QuickStatusUpdateDialog'
import TripHeader from './TripHeader'
import ShareDialog from './ShareDialog'
import DeleteTripDialog from './DeleteTripDialog'
import AssignDriverDialog from './AssignDriverDialog'
import AssignVehicleDialog from './AssignVehicleDialog'
import { TripData } from './TripDashboard.types'
import { KNShareIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'

interface TripTableProps {
  trips: TripData[]
  onChange?: (updatedTrip: TripData, action: string) => void
  useWindowScroll?: boolean
}

const TripTable = ({
  trips,
  onChange,
  useWindowScroll = true,
}: TripTableProps): ReactElement => {
  const { t } = useTranslation()
  const [activeTrip, setActiveTrip] = useState<TripData>()
  const [quickStatusUpdateDialogOpen, setQuickStatusUpdateDialogOpen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [deleteTripDialogOpen, setDeleteTripDialogOpen] = useState(false)
  const [assignDriverDialogOpen, setAssignDriverDialogOpen] = useState(false)
  const [assignVehicleDialogOpen, setAssignVehicleDialogOpen] = useState(false)
  const [tripListState, tripListDispatch] = useContext(TripListContext)
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (tripListState.scroll !== null) {
      // NOTE: doesn't work without even minimal setTimeout
      setTimeout(() => window.scrollTo({ top: tripListState.scroll ?? 0 }), 10)
      tripListDispatch({ type: 'setScroll', payload: null })
    }
  }, [])

  const handleLinkClick = useCallback((row: TripData) => {
    setActiveTrip(row)
    tripListDispatch({ type: 'setScroll', payload: window.pageYOffset })
  }, [])

  const handleQuickStatusUpdateClick = useCallback((row: TripData) => {
    setActiveTrip(row)
    tripListDispatch({ type: 'setScroll', payload: window.pageYOffset })
    setQuickStatusUpdateDialogOpen(true)
  }, [])

  const handleShareClick = useCallback((row: TripData) => {
    setActiveTrip(row)
    setShareDialogOpen(true)
  }, [])

  const handleDeleteClick = useCallback((row: TripData) => {
    setActiveTrip(row)
    setDeleteTripDialogOpen(true)
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'trip',
        label: t('screens.cs.trip_dashboard.card.columns.trip'),
        getValue: (row: TripData) => (
          <Stack alignItems="flex-start">
            <TripHeader
              trip={row}
              linked
              onLinkClick={handleLinkClick}
              highlights={tripListState.filters.keywords}
            />
            <TripCargo trip={row} />
          </Stack>
        ),
        sx: { width: { xs: '24rem', sm: 'auto' } },
      },
      {
        name: 'shipments',
        label: t('screens.cs.trip_dashboard.card.columns.shipments'),
        getValue: (row: TripData) => (
          <KNDataTableOverflow
            row={row}
            label={t('general.view_all')}
            header={t('screens.cs.trip_dashboard.card.columns.shipments')}
            items={row.shipmentNumbers}
            rightAligned
            highlights={tripListState.filters.keywords}
          />
        ),
        sx: { width: { xs: '10rem', xxl: '14rem' } },
      },
      {
        name: 'driver_vehicle',
        label: t('screens.cs.trip_dashboard.card.columns.driver_vehicle'),
        getValue: (row: TripData) => (
          <Stack spacing={1} direction="row" alignItems="center" data-guide="driver-vehicle-actions">
            <Box data-test="driver-vehicle">
              {row.assignedDriver && (
                <KNTypography variant='p3' color="inherit" noWrap sx={{ maxWidth: { xs: '5.5rem', xxl: '9.5rem' } }}>
                  {replaceHighlights(row.assignedDriver.name, tripListState.filters.keywords)}
                </KNTypography>
              )}
              {row.assignedVehicle && (
                <Stack spacing={0.5} direction="row" alignItems="center">
                  <KNTypography variant='p3' color="inherit" noWrap sx={{ maxWidth: { xs: '5.5rem', xxl: '9.5rem' } }}>
                    {replaceHighlights(row.assignedVehicle.displayLicensePlate, tripListState.filters.keywords)}
                  </KNTypography>
                </Stack>
              )}
              {row.secondaryAssignedVehicle && (
                <Stack spacing={0.5} direction="row" alignItems="center">
                  <KNTypography variant='p3' color="inherit" noWrap sx={{ maxWidth: { xs: '5.5rem', xxl: '9.5rem' } }}>
                    {replaceHighlights(row.secondaryAssignedVehicle.displayLicensePlate, tripListState.filters.keywords)}
                  </KNTypography>
                </Stack>
              )}
              {!row.assignedDriver && !row.assignedVehicle && !row.secondaryAssignedVehicle && (
                <Box>{t('screens.cs.trip_dashboard.card.not_assigned')}</Box>
              )}
            </Box>
            <KNDataTableMenu
              label={t('screens.cs.trip_dashboard.card.actions.assign_options')}
              row={row}
              actions={[
                {
                  name: 'assign_driver',
                  label: t('screens.cs.trip_dashboard.card.actions.assign_driver'),
                  onClick: (row: TripData) => {
                    setActiveTrip(row)
                    setAssignDriverDialogOpen(true)
                  },
                },
                {
                  name: 'assign_vehicle',
                  label: t('screens.cs.trip_dashboard.card.actions.assign_vehicle'),
                  onClick: (row: TripData) => {
                    setActiveTrip(row)
                    setAssignVehicleDialogOpen(true)
                  },
                },
              ]}
            />
          </Stack>
        ),
        sx: { width: { xs: '10rem', xxl: '14rem' } },
      },
      {
        name: 'first_pickup',
        label: t('screens.cs.trip_dashboard.card.columns.first_pickup'),
        getValue: (row: TripData) => (
          <>
            {zonedDate(row.earlyPickUpDate, 'full_no_year')}
            <Box>
              <KNCountryFlag countryCode={row.countryCodePickUpPlace} />
              {replaceHighlights(`${row.countryCodePickUpPlace}, ${row.pickUpPlace}`, tripListState.filters.keywords)}
            </Box>
          </>
        ),
        sx: { width: { xs: '10rem', xxl: '14rem' } },
      },
      {
        name: 'last_delivery',
        label: t('screens.cs.trip_dashboard.card.columns.last_delivery'),
        getValue: (row: TripData) => (
          <>
            {zonedDate(row.lateDeliveryDate, 'full_no_year')}
            <Box>
              <KNCountryFlag countryCode={row.countryCodeDeliveryPlace} />
              {replaceHighlights(`${row.countryCodeDeliveryPlace}, ${row.deliveryPlace}`, tripListState.filters.keywords)}
            </Box>
          </>
        ),
        sx: { width: { xs: '10rem', xxl: '14rem' } },
      },
    ],
    [tripListState.filters.keywords]
  )

  const actions = useMemo(
    () => [
      {
        name: 'share',
        label: t('screens.cs.trip_dashboard.card.actions.share_trip'),
        icon: <KNShareIcon data-test="share" />,
        onClick: handleShareClick,
      },
      ...(isSupportUser(user)
        ? [
          {
            name: 'delete',
            label: t('screens.cs.trip_dashboard.card.actions.delete'),
            icon: <DeleteIcon data-test="delete" />,
            onClick: handleDeleteClick,
          }
        ]
        : []),
    ],
    []
  )

  const handleQuickStatusUpdateDialogAction = useCallback((updatedTrip: TripData): void => {
    setQuickStatusUpdateDialogOpen(false)
    onChange?.(updatedTrip, 'quick_status_update')
  }, [])

  const handleQuickStatusUpdateDialogClose = useCallback((): void => {
    setQuickStatusUpdateDialogOpen(false)
  }, [])

  const handleShareDialogAction = useCallback((): void => {
    setShareDialogOpen(false)
  }, [])

  const handleShareDialogClose = useCallback((): void => {
    setShareDialogOpen(false)
  }, [])

  const handleDeleteDialogAction = useCallback((updatedTrip: TripData, action: string): void => {
    setDeleteTripDialogOpen(false)
    onChange?.(updatedTrip, action)
  }, [])

  const handleDeleteDialogClose = useCallback((): void => {
    setDeleteTripDialogOpen(false)
  }, [])

  const handleAssignDriverDialogAction = useCallback((updatedTrip: TripData): void => {
    setAssignDriverDialogOpen(false)
    onChange?.(updatedTrip, 'assign_driver')
  }, [])

  const handleAssignDriverDialogClose = useCallback((): void => {
    setAssignDriverDialogOpen(false)
  }, [])

  const handleAssignVehicleDialogAction = useCallback((updatedTrip: TripData): void => {
    setAssignVehicleDialogOpen(false)
    onChange?.(updatedTrip, 'assign_vehicle')
  }, [])

  const handleAssignVehicleDialogClose = useCallback((): void => {
    setAssignVehicleDialogOpen(false)
  }, [])

  return (
    <>
      <KNDataTable
        columns={columns}
        actions={actions}
        data={trips}
        useWindowScroll={useWindowScroll}
        onRowClick={handleQuickStatusUpdateClick}
        sx={{
          marginX: -2,
          height: '100%',
        }}
        tableSx={{
          minWidth: '68rem',
        }}
      />
      {activeTrip && (
        <>
          <QuickStatusUpdateDialog
            payload={{
              trip: activeTrip,
            }}
            open={quickStatusUpdateDialogOpen}
            onAction={handleQuickStatusUpdateDialogAction}
            onClose={handleQuickStatusUpdateDialogClose}
          />
          <ShareDialog
            payload={{
              trip: activeTrip,
            }}
            open={shareDialogOpen}
            onAction={handleShareDialogAction}
            onClose={handleShareDialogClose}
          />
          <DeleteTripDialog
            payload={{
              trip: activeTrip,
            }}
            open={deleteTripDialogOpen}
            onAction={handleDeleteDialogAction}
            onClose={handleDeleteDialogClose}
          />
          <AssignDriverDialog
            data-test="assign-driver-dialog"
            payload={{
              trip: activeTrip,
            }}
            open={assignDriverDialogOpen}
            onAction={handleAssignDriverDialogAction}
            onClose={handleAssignDriverDialogClose}
          />
          <AssignVehicleDialog
            data-test="assign-vehicle-dialog"
            payload={{
              trip: activeTrip,
            }}
            open={assignVehicleDialogOpen}
            onAction={handleAssignVehicleDialogAction}
            onClose={handleAssignVehicleDialogClose}
          />
        </>
      )}
    </>
  )
}

export default TripTable
