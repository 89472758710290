import { ReactElement, Fragment } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDetailsPopoverSection from './KNDetailsPopoverSection'
import { KNDetailsPopoverListProps } from './types'

const KNDetailsPopoverList = ({ header, description, items }: KNDetailsPopoverListProps): ReactElement | null => {
  if (!description && items.length === 0) {
    return null
  }
  return (
    <KNDetailsPopoverSection
      header={header}
      description={description}
      items={items.map((item, index) => (
        <Stack
          data-test="details-info"
          key={item.label ?? index}
          spacing={item.stacked ? 0 : 4}
          direction={item.stacked ? 'column' : 'row'}
          alignItems="start"
          justifyContent="space-between"
          sx={{
            paddingX: 2,
            paddingY: 0.25,
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}
        >
          {item.label && (
            <KNTypography variant="p6" color="primary.light" data-test="details-info-label">
              {item.label}
            </KNTypography>
          )}
          {item.value && (
            <KNTypography
              data-test="details-info-content"
              color="inherit"
              fontSize="0.75rem"
              textAlign={item.stacked ? 'left' : 'right'}
              sx={{
                lineHeight: '20px',
              }}
            >
              {(Array.isArray(item.value) ? item.value : [item.value]).map((value, index) => (
                <Box key={index} component="span">
                  {value}
                </Box>
              ))}
            </KNTypography>
          )}
        </Stack>
      ))}
    />
  )
}

export default KNDetailsPopoverList
