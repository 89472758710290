import { ReactElement } from 'react'
import { isSameDay, isThisYear, parseISO } from 'date-fns'
import {
  PointLike,
  LineLayerSpecification,
  FillLayerSpecification,
} from 'react-map-gl/maplibre'

import CircleIcon from '@mui/icons-material/Circle'
import { zonedDate } from 'global/helpers/dateFormatters'
import theme from 'assets/theme'
import { getEstimatedSpeedColor } from 'screens/TripDetails/TripDetails.helpers'
import { GeoPoint } from 'screens/TripDetails/TripDetails.types'
import { MapMarker } from './types'
import {
  encodeIconToDataUrl,
  KNGenericMapIcon,
  KNGeofenceMapIcon,
  KNPickupMapIcon,
  KNDeliveryMapIcon,
  KNCustomsMapIcon,
  KNVehicleMapIcon,
  KNHeadingIcon,
  KNDwellMapIcon,
  KNFirstPositionIcon
} from 'components/KN_Molecules/KNIcon/KNMapIcon'

export const getMarkerTypeSize = (type?: string): number => {
  return 24
}

export const getMarkerTypeColor = (type?: string): string => {
  switch (type) {
    case 'PUP':
      return theme.palette.secondary.main
    case 'DEL':
      return theme.palette.primary.main
    case 'CUS':
      return theme.palette.secondary.main
    case 'VEHICLE':
      return theme.palette.success.main
    case 'HEADING':
      return theme.palette.primary.main
    case 'GEOFENCE':
      return theme.palette.primary.light
    case 'DWELL':
      return '#ffa8a8'
    default:
      return theme.palette.primary.main
  }
}

export const getMarkerIcon = (marker: MapMarker): ReactElement => {
  const size = getMarkerTypeSize(marker.type)
  const color = marker.color ?? getMarkerTypeColor(marker.type)
  switch (marker.type) {
    case 'PUP':
      return <KNPickupMapIcon size={size} color={color} state={marker.state} />
    case 'DEL':
      return <KNDeliveryMapIcon size={size} color={color} state={marker.state} />
    case 'CUS':
      return <KNCustomsMapIcon size={size} color={color} state={marker.state} />
    case 'VEHICLE':
      return <KNVehicleMapIcon size={size} color={color} state={marker.state} />
    case 'HEADING':
      return <KNHeadingIcon size={size} color={color} state={marker.state} />
    case 'FIRST_POSITION':
      return <KNFirstPositionIcon size={size} color={color} state={marker.state} />
    case 'GEOFENCE':
      return <KNGeofenceMapIcon size={size} color={color} state={marker.state} />
    case 'DWELL':
      return <KNDwellMapIcon size={size} color={color} state={marker.state} />
    default:
      return <KNGenericMapIcon size={size} color={color} label={marker.label} state={marker.state} />
  }
}

export const getMarkerOffset = (marker: MapMarker): PointLike => {
  switch (marker.type) {
    case 'VEHICLE':
    case 'HEADING':
      return [0, 0]
    case 'FIRST_POSITION':
      return [0, -6]
    default:
      return [0, -8]
  }
}

export const getRoutesLayer = (speed: string): LineLayerSpecification => {
  return {
    source: 'routes',
    id: `routes_${speed}`,
    type: 'line',
    filter: [
      'all',
      ['==', 'speed', speed],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': ['get', 'color'],
      'line-width': [
        'interpolate',
        ['exponential', 1.2],
        ['zoom'],
        2,
        2,
        6,
        4,
        10,
        6,
      ],
      ...(speed === 'interruption' && {
        'line-dasharray': [0.5, 2],
      }),
    }
  }
}

export const getGeofencesLayer = (outline = false): LineLayerSpecification | FillLayerSpecification => {
  if (outline) {
    return {
      source: 'geofences',
      id: 'geofences_outline',
      type: 'line',
      paint: {
        'line-color': [
          'coalesce',
          ['get', 'color'],
          getMarkerTypeColor(),
        ],
        'line-opacity': 0.5,
        'line-width': [
          'interpolate',
          ['exponential', 1.2],
          ['zoom'],
          0,
          1,
          16,
          3,
        ],
      },
    } as LineLayerSpecification
  }
  return {
    source: 'geofences',
    id: 'geofences',
    type: 'fill',
    paint: {
      'fill-color': [
        'coalesce',
        ['get', 'color'],
        getMarkerTypeColor(),
      ],
      'fill-opacity': 0.1,
    }
  } as FillLayerSpecification
}

export const getHeadingDate = (timestamp: string, lastTimestamp?: string): string | null => {
  const parsedTimestamp = parseISO(timestamp)
  if (!parsedTimestamp) {
    return null
  }
  const fullDateFormat = isThisYear(parsedTimestamp) ? 'full_no_year' : 'full'
  const mediumDateFormat = isThisYear(parsedTimestamp) ? 'medium_no_year' : 'medium'
  if (!lastTimestamp) {
    return `${zonedDate(parsedTimestamp, fullDateFormat, 'UTC') as string} (UTC)`
  }
  const parsedLastTimestamp = parseISO(lastTimestamp)
  if (!parsedLastTimestamp) {
    return null
  }
  if (isSameDay(parsedTimestamp, parsedLastTimestamp)) {
    return `${zonedDate(parsedTimestamp, mediumDateFormat, 'UTC') as string} ${zonedDate(parsedTimestamp, 'time', 'UTC') as string}-${zonedDate(parsedLastTimestamp, 'time', 'UTC') as string} (UTC)`
  }
  return `${zonedDate(parsedTimestamp, fullDateFormat, 'UTC') as string} - ${zonedDate(parsedLastTimestamp, fullDateFormat, 'UTC') as string} (UTC)`
}
