import { useEffect, useState, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

// KN imports
import { UserContext } from 'context/authentication/UserContext'
import { UserListContext, UserFiltersValues } from 'context/users/UserListContext'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import UserFilters from './UserFilters'
import UserTable from './UserTable'
import CreateOrEditUserDialog from './CreateOrEditUserDialog'
import BulkCreateUsersDialog from './BulkCreateUsersDialog'

// Functional
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'
import { isCompanyType, hasRole } from 'global/helpers/authorization'
import { getUsers } from './UserManager.service'
import { getCompanies, sortCompanies } from 'screens/CompanyManager/CompanyManager.service'

// Types
import { UserData } from './UserManager.types'
import { CompanyType } from 'context/authentication/Company.types'
import { Role } from 'context/authentication/Role.types'

const UserManager = (): ReactElement => {
  const { t } = useTranslation()
  const { user } = useContext(UserContext)
  const [usersData, setUsersData] = useState<UserData[]>([])
  const [filteredUsersData, setFilteredUsersData] = useState<UserData[]>([])
  const [loading, setLoading] = useState(true)
  const [userListState, userListDispatch] = useContext(UserListContext)
  const [createOrEditUserDialogOpen, setCreateOrEditUserDialogOpen] = useState(false)
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false)
  const [bulkCreateUsersDialogOpen, setBulkCreateUsersDialogOpen] = useState(false)

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    const [companies, users] = await Promise.all([
      userListState.companiesPreloaded
        ? userListState.companies
        : isCompanyType(CompanyType.Operator) && hasRole(Role.Admin)
        ? getCompanies()
        : user?.companies.filter((company) => company.role === Role.Admin) ?? [],
      getUsers(),
    ])
    if (!userListState.companiesPreloaded) {
      userListDispatch({ type: 'setCompanies', payload: companies })
    }
    setUsersData(
      users.map((user) => {
        user.companies = []
        for (const cid in user.roles) {
          const matchedCompany = companies.find((company) => company.cid === cid)
          if (matchedCompany) {
            user.companies.push({
              ...matchedCompany,
              role: user.roles[cid],
            })
          }
        }
        user.companies.sort(sortCompanies)
        return user
      })
    )
    setLoading(false)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
    if (!isCompanyType(CompanyType.Operator)) {
      analyticsPageView('polestar/users_list')
    }
  }, [])

  useEffect(() => {
    filterData(userListState.filters)
  }, [usersData, userListState.filters])

  const handleOnChange = useCallback(async (): Promise<void> => {
    await fetchData()
  }, [])

  const filterData = (filters: UserFiltersValues): void => {
    setFilteredUsersData(
      usersData.filter((user) => {
        const lowercaseKeywords = filters.keywords?.map((keyword: string) => keyword.toLowerCase())
        let keywordsCondition = true
        if (lowercaseKeywords?.length) {
          const lowercaseValues = [
            user.email,
            user.displayName || '',
            ...(user.companies?.reduce(
              (keywords: string[], company) => [...keywords, company.displayName, ...company.customerIdentifiers],
              []
            ) ?? []),
          ].map((value: string) => value.toLowerCase())
          keywordsCondition = lowercaseValues
            .map((value) => {
              return lowercaseKeywords.map((keyword) => value.includes(keyword)).some((condition: boolean) => condition)
            })
            .some((condition: boolean) => condition)
        }

        return [keywordsCondition].every((condition: boolean) => condition)
      })
    )
  }

  const handleAddClick = useCallback((): void => {
    setCreateOrEditUserDialogOpen(true)
    analyticsEvent('polestar_add_user_button_clicked')
  }, [])

  const handleCreateOrEditUserDialogAction = useCallback(async (): Promise<void> => {
    setCreateOrEditUserDialogOpen(false)
    await fetchData()
  }, [])

  const handleCreateOrEditUserDialogClose = useCallback((): void => {
    setCreateOrEditUserDialogOpen(false)
  }, [])

  const handleBulkCreateClick = useCallback((): void => {
    setBulkCreateUsersDialogOpen(true)
  }, [])

  const handleBulkCreateUsersDialogAction = useCallback(async (): Promise<void> => {
    setBulkCreateUsersDialogOpen(false)
    await fetchData()
  }, [])

  const handleBulkCreateUsersDialogClose = useCallback((): void => {
    setBulkCreateUsersDialogOpen(false)
  }, [])

  return (
    <Container maxWidth="xl" data-test="users-container">
      {loading ? (
        <KNLoader>
          <KNTypography>{t('screens.shared.user_manager.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <>
          <Paper elevation={8} sx={{ padding: 2 }}>
            <Stack direction="column" spacing={1} mb={1}>
              <UserFilters />
              <Stack
                spacing={1}
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
                alignItems={{ xs: 'start', md: 'center' }}
                mb={2}
              >
                <KNTypography data-test="users-count" variant="h6">
                  {t('screens.shared.user_manager.users_count', { count: filteredUsersData.length })}
                </KNTypography>
                {hasRole(Role.Admin) && (
                  <Stack direction="row" spacing={1}>
                    <KNButton
                      onClick={handleAddClick}
                      data-test="add-new-user-button"
                      variant="contained"
                      color="secondary"
                      size="small"
                    >
                      {t('screens.shared.user_manager.card.actions.new')}
                    </KNButton>
                    {isCompanyType(CompanyType.Operator) && (
                      <KNButton
                        onClick={handleBulkCreateClick}
                        data-test="bulk-create-users-button"
                        variant="contained"
                        color="secondary"
                        size="small"
                      >
                        {t('screens.shared.user_manager.card.actions.bulk')}
                      </KNButton>
                    )}
                  </Stack>
                )}
              </Stack>
            </Stack>
            <UserTable users={filteredUsersData} onChange={handleOnChange} />
          </Paper>
          {hasRole(Role.Admin) && (
            <>
              <CreateOrEditUserDialog
                open={createOrEditUserDialogOpen}
                onAction={handleCreateOrEditUserDialogAction}
                onClose={handleCreateOrEditUserDialogClose}
              />
              {isCompanyType(CompanyType.Operator) && (
                <BulkCreateUsersDialog
                  open={bulkCreateUsersDialogOpen}
                  onAction={handleBulkCreateUsersDialogAction}
                  onClose={handleBulkCreateUsersDialogClose}
                />
              )}
            </>
          )}
        </>
      )}
    </Container>
  )
}

export default UserManager
