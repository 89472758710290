import { useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Stack from '@mui/material/Stack'
import WorkIcon from '@mui/icons-material/Work'
import ApartmentIcon from '@mui/icons-material/Apartment'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

// KN imports
import { CompanyType } from 'context/authentication/Company.types'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { KNCrownIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'

interface CompanyTypeLabelProps {
  type: CompanyType
  compact?: boolean
}

const getTypeIcon = (type: CompanyType): any => {
  switch (type) {
    case 'Operator':
      return KNCrownIcon
    case 'Carrier':
      return LocalShippingIcon
    case 'Customer':
    default:
      return ApartmentIcon
  }
}

const CompanyTypeLabel = ({ type, compact }: CompanyTypeLabelProps): ReactElement => {
  const { t } = useTranslation()
  const TypeIcon = getTypeIcon(type)
  return (
    <Stack spacing={0.5} direction="row" alignItems="center">
      <TypeIcon sx={{ color: 'primary.light', fontSize: '1rem' }} />
      {!compact && (
        <KNTypography variant="p6" color="primary.light">
          {t(`screens.shared.company_manager.company_types.${type}`)}
        </KNTypography>
      )}
    </Stack>
  )
}

export default CompanyTypeLabel
