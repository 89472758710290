import { useCallback, useEffect, useState, useContext, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from 'i18n'

// @mui imports
import Alert from '@mui/material/Alert'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Container from '@mui/material/Container'
import DangerousIcon from '@mui/icons-material/Dangerous'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { SystemStyleObject } from '@mui/system'
import { Theme } from '@mui/material/styles'

// KN imports
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'
import { getLogo } from 'global/helpers/getLogo'
import { validateEmail } from 'global/helpers/validators'
import { getUserType } from './ResetPassword.service'

// Data
import { resetPasswordTranslations } from './ResetPassword.data'

// Context
import { UserContext } from 'context/authentication/UserContext'

const ResetPassword = (): ReactElement => {
  // Screen specific //
  const urlParams = new URLSearchParams(window.location.search)
  const oobCode = urlParams.get('oobCode')
  const urlEmail = urlParams.get('email')
  const [codeError, setCodeError] = useState('')
  const [loading, setLoading] = useState(false)
  const [backButton, setBackButton] = useState('')
  const environment = process.env.REACT_APP_API_ENV
  const backButtonLink =
    backButton === 'RCV'
      ? `https://${environment !== 'prod' ? environment + '.' : ''}shipmentinsight.kuehne-nagel.com/login`
      : `https://${environment !== 'prod' ? environment + '.' : ''}carriersolution.kuehne-nagel.com/login`

  // Password reset //
  const [password, setPassword] = useState('')
  const [weakPassword, setWeakPassword] = useState(false)
  const [passwordResetError, setPasswordResetError] = useState('')
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const handleTogglePasswordVisibility = (): void => {
    setShowPassword(!showPassword)
  }

  // Email reset resend //
  const [email, setEmail] = useState('')
  const [emailSendingError, setEmailSendingError] = useState('')
  const [emailSendingSuccess, setEmailSendingSuccess] = useState(false)

  // Context
  const { resetUser, verifyPasswordResetCode, confirmPasswordReset } = useContext(UserContext)

  const sendResetEmail = async (): Promise<any> => {
    await resetUser(email).then(
      () => {
        setEmailSendingSuccess(true)
        setLoading(false)
        setEmail('')
      },
      (error: Error) => {
        setEmailSendingError(error.message)
        setLoading(false)
        setEmail('')
      }
    )
  }

  const setSendResetEmail = useCallback((): void => {
    void sendResetEmail()
  }, [email])

  const resetPassword = async (password: string): Promise<any> => {
    setLoading(true)
    if (oobCode) {
      return await confirmPasswordReset(oobCode, password).then(
        () => {
          setPasswordResetSuccess(true)
          setLoading(false)
          setPassword('')
          analyticsEvent('polestar_password_reset')
        },
        () => {
          setPasswordResetError('invalidactioncode')
          setLoading(false)
          setPassword('')
        }
      )
    }
  }

  const setResetPassword = useCallback((): void => {
    if (weakPassword || password.length === 0) {
      return
    }
    void resetPassword(password)
  }, [password])

  const validateActionCode = (actionCode: string): void => {
    setLoading(true)
    verifyPasswordResetCode(actionCode)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setCodeError('invalidactioncode')
        setLoading(false)
      })
  }

  useEffect(() => {
    analyticsPageView('polestar/reset_pw')
    // Check the oobCode for the status of the password reset code
    validateActionCode(oobCode ?? '')
  }, [])

  useEffect(() => {
    if (urlEmail) {
      getUserType(urlEmail)
        .then((response) => {
          setBackButton(response)
        })
        .catch((error) => {
          console.log('Error getting user', error)
        })
    }
  }, [urlEmail])

  // Data
  const { t } = useTranslation()
  const { translation } = resetPasswordTranslations()

  return (
    <Container maxWidth="xs">
      <Box my={10} sx={{ position: 'relative' }}>
        {/* Error or code invalid/ expired */}
        {codeError && !loading && (
          <Alert variant="filled" severity="info" sx={{ my: 2 }}>
            {i18n.t(`shared.reset_password.${codeError}`)}
          </Alert>
        )}
        {/* Loading */}
        {loading && (
          <KNLoader>
            <KNTypography>{translation.loading}</KNTypography>
          </KNLoader>
        )}
        {/* Email sent again */}
        {(emailSendingError || emailSendingSuccess) && !loading && (
          <>
            {getLogo('logo', '100%', '64px')}
            <Box my={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {emailSendingSuccess ? (
                  <CheckCircleIcon color="success" sx={{ height: '100px', width: '100px' }} />
                ) : (
                  <DangerousIcon color="error" sx={{ height: '100px', width: '100px' }} />
                )}
                <Box my={3}>
                  <KNTypography variant="h4" color="black">
                    {emailSendingSuccess ? translation.resetSuccessful : translation.resetFail}
                  </KNTypography>
                  <KNTypography variant="p3" color="grey">
                    {emailSendingSuccess ? translation.emailResetSuccessfulMsg : translation.resetFailMsg}
                  </KNTypography>
                </Box>
              </Stack>
            </Box>
          </>
        )}
        {/* Reset done */}
        {(passwordResetSuccess || passwordResetError) && !loading && (
          <>
            {getLogo('logo', '100%', '64px')}
            <Box my={2} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Stack
                spacing={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                {passwordResetSuccess ? (
                  <CheckCircleIcon color="success" sx={{ height: '100px', width: '100px' }} />
                ) : (
                  <DangerousIcon color="error" sx={{ height: '100px', width: '100px' }} />
                )}
                <Box my={3}>
                  <KNTypography variant="h4" color="black">
                    {passwordResetSuccess ? translation.resetSuccessful : translation.resetFail}
                  </KNTypography>
                  <KNTypography variant="p3" color="grey">
                    {passwordResetSuccess ? translation.resetSuccessfulMsg : translation.resetFailMsg}
                  </KNTypography>
                </Box>
                <a href={backButtonLink} style={{ textDecoration: 'none' }}>
                  <KNButton variant="text" color="primary" startIcon={<ArrowBackIcon />}>
                    {translation.backButton}
                  </KNButton>
                </a>
              </Stack>
            </Box>
          </>
        )}
        {/* Reset active state */}
        {!passwordResetError && !passwordResetSuccess && codeError === '' && !loading && (
          <>
            <Box my={3}>
              <>
                <form
                  onSubmit={(e): void => {
                    e.preventDefault()
                    setResetPassword()
                  }}
                >
                  {getLogo('logo', '100%', '64px')}
                  <Stack spacing={2} px={{ xs: 0, sm: 2 }}>
                    <Box>
                      <KNTypography
                        variant="p3"
                        mb={0.5}
                        sx={{
                          color: ({ palette: { grey } }: Theme): string =>
                            passwordResetError > '' ? grey[300] : grey[600],
                          fontWeight: ({ typography: { fontWeightLight } }: Theme): number => fontWeightLight,
                        }}
                      >
                        {translation.new_password}
                      </KNTypography>
                      <TextField
                        error={weakPassword}
                        variant="outlined"
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        onChange={(e): void => {
                          const newPassword = e.target.value
                          setWeakPassword(newPassword.length > 0 && newPassword.length < 6)
                          setPassword(newPassword)
                        }}
                        helperText={translation.resetPasswordRequirements}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                              aria-label="toggle password visibility"
                              sx={({ palette: { grey } }): SystemStyleObject<Theme> => ({
                                color: grey[300],
                                transition: 'color 200ms',
                                '&:hover': {
                                  color: grey[400],
                                },
                              })}
                            >
                              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                    <Box my={2}>
                      <KNButton
                        dataAttribute="login"
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={(): void => setResetPassword()}
                        disabled={weakPassword}
                        sx={{
                          fontSize: ({ typography: { size } }: Theme): string => size.md,
                          height: '44px',
                        }}
                      >
                        {translation.resetPasswordButton}
                      </KNButton>
                    </Box>
                    <Box my={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <a href={backButtonLink} style={{ textDecoration: 'none' }}>
                        <KNButton variant="text" color="primary" startIcon={<ArrowBackIcon />}>
                          {translation.backButton}
                        </KNButton>
                      </a>
                    </Box>
                  </Stack>
                </form>
              </>
            </Box>
          </>
        )}
        {/* Email resend state */}
        {codeError && !emailSendingError && !emailSendingSuccess && !loading && (
          <Box my={3}>
            <form>
              {getLogo('logo', '100%', '64px')}
              <Stack spacing={2} px={{ xs: 0, sm: 2 }}>
                <Box>
                  <KNTypography
                    variant="p5"
                    mb={0.5}
                    sx={{
                      color: ({ palette: { grey } }: Theme): string => grey[600],
                      fontWeight: ({ typography: { fontWeightLight } }: Theme): number => fontWeightLight,
                    }}
                  >
                    {translation.email}
                  </KNTypography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    autoComplete="email"
                    onChange={(e): void => setEmail(e.target.value)}
                  />
                </Box>
                <Box my={2}>
                  <KNButton
                    dataAttribute="login"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!validateEmail(email)}
                    onClick={(): void => setSendResetEmail()}
                    sx={{
                      fontSize: ({ typography: { size } }: Theme): string => size.md,
                      height: '44px',
                    }}
                  >
                    {translation.resetPasswordButton}
                  </KNButton>
                </Box>
              </Stack>
            </form>
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default ResetPassword
