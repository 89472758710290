import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

// KN Components
import { CarrierListContext } from 'context/carriers/CarrierListContext'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNAdvancedDropdown from 'components/KN_Molecules/KNAdvancedDropdown/KNAdvancedDropdown'

const CarrierFilters = (): ReactElement => {
  const { t } = useTranslation()
  const [carrierListState, carrierListDispatch] = useContext(CarrierListContext)
  const [payload, setPayload] = useState<object | null>(null)
  const [debouncedPayload] = useDebounce(payload, 100)

  useEffect(() => {
    if (debouncedPayload) {
      carrierListDispatch({
        type: 'setFilters',
        payload: debouncedPayload,
      })
    }
    setPayload(null)
  }, [debouncedPayload])

  const handleResetClick = useCallback((): void => {
    carrierListDispatch({ type: 'resetFilters' })
  }, [])

  return (
    <Box data-test="carrier-filter-container" sx={{ position: 'relative' }}>
      <Grid container spacing={2}>
        <Grid data-test="carrier-search-input" item xs={12} sm={4}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={carrierListState.filters.keywords ?? []}
            onChange={(event, newValue): void => {
              setPayload({
                keywords: [...newValue] as string[],
              })
            }}
            renderTags={(tagValue, getTagProps): ReactElement[] =>
              tagValue.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip {...getTagProps({ index })} variant="outlined" size="small" label={option} />
              ))
            }
            renderInput={(params): ReactElement => (
              <TextField {...params} label={t('screens.cs.carrier_manager.filters.search')} />
            )}
          />
        </Grid>
        <Grid data-test="carrier-integrations-dropdown" item xs={12} sm={2}>
          <KNAdvancedDropdown
            label={t('screens.cs.carrier_manager.filters.integrations')}
            options={['NONE', 'I_BROKER', 'I_BROKER_V2'].map((integration) => {
              return {
                value: integration,
                label: t(`screens.cs.carrier_manager.integrations.${integration}`),
              }
            })}
            value={carrierListState.filters.integrations ?? []}
            onChange={(event): void => {
              setPayload({
                integrations: event.target.value as string[],
              })
            }}
            onDelete={(value) => {
              setPayload({
                integrations: carrierListState.filters.integrations?.filter((integration) => integration !== value),
              })
            }}
            size="small"
            multiple
          />
        </Grid>
        <Grid data-test="carrier-telematics-dropdown" item xs={12} sm={2}>
          <KNAdvancedDropdown
            label={t('screens.cs.carrier_manager.filters.telematics')}
            options={['NONE', 'S3PWEB', 'NICPLACE'].map((telematics) => {
              return {
                value: telematics,
                label: t(`screens.cs.carrier_manager.telematics.${telematics}`),
              }
            })}
            value={carrierListState.filters.telematics ?? []}
            onChange={(event): void => {
              const newValue = event.target.value as string[]
              setPayload({
                telematics: newValue,
              })
            }}
            onDelete={(value) => {
              setPayload({
                telematics: carrierListState.filters.telematics?.filter((telematics) => telematics !== value),
              })
            }}
            size="small"
            multiple
          />
        </Grid>
        <Grid data-test="carrier-features-dropdown" item xs={12} sm={2}>
          <KNAdvancedDropdown
            label={t('screens.cs.carrier_manager.filters.features')}
            options={[
              {
                value: 'recommendedRoutes',
                label: t(`screens.cs.carrier_manager.card.columns.recommended_routes`),
              },
            ]}
            value={carrierListState.filters.features ?? []}
            onChange={(event): void => {
              const newValue = event.target.value as string[]
              setPayload({
                features: newValue,
              })
            }}
            onDelete={(value) => {
              setPayload({
                features: carrierListState.filters.features?.filter((feature) => feature !== value),
              })
            }}
            size="small"
            multiple
          />
        </Grid>
      </Grid>
      {carrierListState.filtersDirty && (
        <KNButton
          size="small"
          variant="text"
          color="primary"
          data-test="reset-carrier-filters-button"
          onClick={handleResetClick}
          sx={{
            padding: 0,
            minHeight: 'auto',
          }}
        >
          {t('general.reset_filters')}
        </KNButton>
      )}
    </Box>
  )
}

export default CarrierFilters
