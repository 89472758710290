import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// KN imports //
import KNAdvancedDropdown from 'components/KN_Molecules/KNAdvancedDropdown/KNAdvancedDropdown'
import { getFieldErrorMessages } from 'global/helpers/form'
import { KNFormDropdownProps } from './types'

const KNFormDropdown = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  options,
  size,
  sx,
  disabled,
  onSetValue,
}: KNFormDropdownProps<T>): ReactElement => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
      <KNAdvancedDropdown
        fullWidth
        label={label}
        size={size}
        sx={sx}
        disabled={formState.isSubmitting || disabled}
        inputRef={ref}
        value={value}
        onChange={(event): void => {
          onChange(event)
          if (onSetValue) onSetValue(event)
        }}
        options={options}
        error={invalid}
        helperText={getFieldErrorMessages(error)}
        data-test={name}
      />
    )}
  />
)

export default KNFormDropdown
