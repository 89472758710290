import { memo, useContext, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'

// @mui material imports
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

// KN Components
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Logic
import { UserContext } from 'context/authentication/UserContext'
import { getUserPropertiesWithValues } from './ProfilePage.helpers'

const AccountInformation = (): ReactElement | null => {
  const { t } = useTranslation()
  const { user } = useContext(UserContext)

  if (!user) {
    return null
  }

  const userProperties = getUserPropertiesWithValues(user)
  return (
    <Paper
      elevation={8}
      sx={{
        padding: 2,
      }}
    >
      <KNTypography variant="p2" mb={1} >
        {t('screens.shared.profile.account_information')}
      </KNTypography>
      <Stack direction="column" spacing={1}>
        {userProperties.map((property) => (
          <Box key={property.label}>
            <KNTypography variant="p6" color="primary.light">
              {property.label}
            </KNTypography>
            <KNTypography color="primary.dark">{property.value}</KNTypography>
          </Box>
        ))}
      </Stack>
    </Paper>
  )
}

export default AccountInformation
