import { cloneElement, ReactElement } from 'react'

// @mui imports
import Stack from '@mui/material/Stack'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types
import { KNCaptionProps } from './types'

const KNCaption = ({ children, icon, color, reversed = false, onClick, sx }: KNCaptionProps): ReactElement => (
  <Stack
    data-test="caption"
    spacing={0.5}
    direction={reversed ? 'row-reverse' : 'row'}
    alignItems="center"
    sx={{
      minHeight: '20px',
      cursor: onClick ? 'pointer' : undefined,
      ...sx,
    }}
    onClick={onClick}
  >
    {icon &&
      cloneElement(icon, {
        fontSize: 'small',
        color: color ?? 'text',
      })}
    {children && (
      <KNTypography
        color={color ?? 'text.main'}
        variant={'caption'}
      >
        {children}
      </KNTypography>
    )}
  </Stack>
)

export default KNCaption
