import { useState, useEffect, useRef, ReactElement, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import InfoIcon from '@mui/icons-material/Info'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import { regexEmail } from 'global/helpers/validators'
import { zonedDate } from 'global/helpers/dateFormatters'
import { UserContext } from 'context/authentication/UserContext'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNTooltipButton from 'components/KN_Molecules/KNTooltipButton/KNTooltipButton'
import { KNTooltipButtonHandle } from 'components/KN_Molecules/KNTooltipButton/types'
import KNClipboardField from 'components/KN_Molecules/KNClipboardField/KNClipboardField'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormPhone from 'components/KN_Molecules/KNForm/KNFormPhone'
import { TripData } from './TripDashboard.types'
import { getShareToken, shareTokenViaEmail, shareTokenViaSms, shareTokenViaWhatsApp } from './TripDashboard.service'

interface ShareDialogPayload {
  trip: TripData
  weblinkToken?: string
}

interface ShareDialogProps {
  payload: ShareDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface ShareViaEmailFormValues {
  weblink: string
  email: string
}

export interface ShareViaSmsFormValues {
  weblink: string
  phoneNumber: string
}

export interface ShareViaWhatsAppFormValues {
  weblink: string
  phoneNumber: string
}

const ShareDialog = ({ payload, open, onAction, onClose }: ShareDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { user } = useContext(UserContext)
  const location = useLocation()
  const {
    handleSubmit: handleSubmitEmail,
    reset: resetEmail,
    control: controlEmail,
    formState: formStateEmail,
    setError: setErrorEmail,
  } = useForm<ShareViaEmailFormValues>()
  const {
    handleSubmit: handleSubmitSms,
    reset: resetSms,
    control: controlSms,
    formState: formStateSms,
    getValues: getValuesSms,
    setError: setErrorSms,
  } = useForm<ShareViaSmsFormValues>()
  const {
    handleSubmit: handleSubmitWhatsApp,
    reset: resetWhatsApp,
    control: controlWhatsApp,
    formState: formStateWhatsApp,
    getValues: getValuesWhatsApp,
    setError: setErrorWhatsApp,
  } = useForm<ShareViaSmsFormValues>()

  const emailButtonRef = useRef<KNTooltipButtonHandle>(null)
  const smsButtonRef = useRef<KNTooltipButtonHandle>(null)
  const whatsAppButtonRef = useRef<KNTooltipButtonHandle>(null)
  const [shareToken, setShareToken] = useState('')
  const [loading, setLoading] = useState(true)

  const fetchShareToken = async (): Promise<void> => {
    setLoading(true)
    const { weblinkToken } = await getShareToken(payload.trip.entityId, payload.weblinkToken)
    setShareToken(`${window.location.origin}/wl/${weblinkToken}`)
    setLoading(false)
  }

  const isWhatsAppUser = (): boolean =>
    [
      'sarah.rees@kuehne-nagel.com',
      'external.mariusz.niewiadomski@kuehne-nagel.com',
      'maksim.shadchin@kuehne-nagel.com',
      'benoit.therond@kuehne-nagel.com',
      'external.muriel.riviere@kuehne-nagel.com', // UAT
      'muriel.riviere@kuehne-nagel.com', // PROD
      'peter.hammond@kuehne-nagel.com',
      'polestar.tech.carrier.solution@kuehne-nagel.com',
    ].includes(user?.email.toLowerCase() ?? '')

  useEffect(() => {
    if (open) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchShareToken()
      resetEmail(
        processDefaultValues({
          email: payload.trip.assignedDriver?.email,
        })
      )
      resetSms(
        processDefaultValues({
          phoneNumber: payload.trip.assignedDriver?.contactNumber,
        })
      )
      resetWhatsApp(
        processDefaultValues({
          phoneNumber: payload.trip.assignedDriver?.contactNumber,
        })
      )
    } else {
      setShareToken('')
    }
  }, [open])

  const onSubmitEmail: SubmitHandler<ShareViaEmailFormValues> = async (data: ShareViaEmailFormValues) => {
    try {
      await shareTokenViaEmail(payload.trip.entityId, { ...data, weblink: shareToken }, payload.weblinkToken)
      emailButtonRef.current?.showTooltip()
      analyticsEvent('polestar_cs_trip_shared', [getRouteName(location.pathname), 'email'])
    } catch (error) {
      setErrorEmail('root', processServerErrorMessages(error))
    }
  }

  const onSubmitSms: SubmitHandler<ShareViaSmsFormValues> = async (data: ShareViaSmsFormValues) => {
    try {
      await shareTokenViaSms(payload.trip.entityId, { ...data, weblink: shareToken }, payload.weblinkToken)
      smsButtonRef.current?.showTooltip()
      analyticsEvent('polestar_cs_trip_shared', [getRouteName(location.pathname), 'sms'])
    } catch (error) {
      setErrorSms('root', processServerErrorMessages(error))
    }
  }

  const onSubmitWhatsApp: SubmitHandler<ShareViaWhatsAppFormValues> = async (data: ShareViaWhatsAppFormValues) => {
    try {
      await shareTokenViaWhatsApp(payload.trip.entityId, { ...data, weblink: shareToken }, payload.weblinkToken)
      whatsAppButtonRef.current?.showTooltip()
      analyticsEvent('polestar_cs_trip_shared', [getRouteName(location.pathname), 'whatsapp'])
    } catch (error) {
      setErrorWhatsApp('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      preventClosing={formStateEmail.isSubmitting || formStateSms.isSubmitting}
      title={`${payload.trip.voyageNumber} — ${t('screens.cs.trip_dashboard.card.actions.share_trip')}`}
    >
      <KNDialogFormErrors errors={formStateEmail.errors?.root} />
      <KNDialogFormErrors errors={formStateSms.errors?.root} />
      <KNDialogFormErrors errors={formStateWhatsApp.errors?.root} />
      {(payload.trip.lastShareChannel && payload.trip.lastShareTimestamp) && (
        <DialogContentText mb={1}>
          <KNCaption icon={<InfoIcon />}>
            {t(`screens.cs.trip_dashboard.share.last_shared_via.${payload.trip.lastShareChannel}`, {
              timestamp: zonedDate(payload.trip.lastShareTimestamp, 'full_no_year')
            })}
          </KNCaption>
        </DialogContentText>
      )}
      <DialogContentText mb={2}>
        {t('screens.cs.trip_dashboard.share.message')}
      </DialogContentText>
      <Stack spacing={2}>
        <KNClipboardField
          value={shareToken}
          loading={loading}
          onAction={(): void => {
            analyticsEvent('polestar_cs_trip_shared', [getRouteName(location.pathname), 'link'])
          }}
        />
        <KNForm onSubmit={handleSubmitEmail(onSubmitEmail)}>
          <Stack
            data-test="email"
            spacing={{ xs: 0.5, sm: 1 }}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'start', sm: 'end' }}
          >
            <KNFormText
              name="email"
              label={t('screens.cs.trip_dashboard.share.via_email')}
              placeholder={t('screens.cs.trip_dashboard.share.email_placeholder')}
              control={controlEmail}
              disabled={loading}
              rules={{
                pattern: {
                  value: regexEmail,
                  message: t('form.validation.invalid_email'),
                },
                required: t('form.validation.required'),
              }}
            />
            <KNTooltipButton
              ref={emailButtonRef}
              tooltip={t('form.sent')}
              variant="contained"
              size="small"
              color="secondary"
              disabled={loading}
              loading={formStateEmail.isSubmitting}
              onClick={handleSubmitEmail(onSubmitEmail)}
            >
              {t('form.send')}
            </KNTooltipButton>
          </Stack>
        </KNForm>
        <KNForm onSubmit={handleSubmitSms(onSubmitSms)}>
          <Stack
            data-test="sms"
            spacing={{ xs: 0.5, sm: 1 }}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'start', sm: 'end' }}
          >
            <KNFormPhone
              name="phoneNumber"
              label={t('screens.cs.trip_dashboard.share.via_sms')}
              placeholder={t('screens.cs.trip_dashboard.share.phone_placeholder')}
              control={controlSms}
              disabled={loading}
              rules={{
                required: t('form.validation.required'),
              }}
              getValues={getValuesSms}
            />
            <KNTooltipButton
              ref={smsButtonRef}
              tooltip={t('form.sent')}
              variant="contained"
              size="small"
              color="secondary"
              disabled={loading}
              loading={formStateSms.isSubmitting}
              onClick={handleSubmitSms(onSubmitSms)}
            >
              {t('form.send')}
            </KNTooltipButton>
          </Stack>
        </KNForm>
        {isWhatsAppUser() && (
          <KNForm onSubmit={handleSubmitWhatsApp(onSubmitWhatsApp)}>
            <Stack
              data-test="whatsapp"
              spacing={{ xs: 0.5, sm: 1 }}
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'start', sm: 'end' }}
            >
              <KNFormPhone
                name="phoneNumber"
                label={t('screens.cs.trip_dashboard.share.via_whatsapp')}
                placeholder={t('screens.cs.trip_dashboard.share.phone_placeholder')}
                control={controlWhatsApp}
                disabled={loading}
                rules={{
                  required: t('form.validation.required'),
                }}
                getValues={getValuesWhatsApp}
              />
              <KNTooltipButton
                ref={whatsAppButtonRef}
                tooltip={t('form.sent')}
                variant="contained"
                size="small"
                color="secondary"
                disabled={loading}
                loading={formStateWhatsApp.isSubmitting}
                onClick={handleSubmitWhatsApp(onSubmitWhatsApp)}
              >
                {t('form.send')}
              </KNTooltipButton>
            </Stack>
          </KNForm>
        )}
      </Stack>
    </KNDialog>
  )
}

export default ShareDialog
