import { ReactElement } from 'react'
import i18n from 'i18n'

// @mui imports
import DialogContentText from '@mui/material/DialogContentText'

// KN imports
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

export interface RedirectDialogProps {
  open: boolean
}

const getRedirectLink = () =>
  window.location.href.replace('carriersolution.etrucknow.com', 'carriersolution.kuehne-nagel.com')
    .replace('visibility.etrucknow.com', 'shipmentinsight.kuehne-nagel.com')

const getStrings = () => {
  if (window.location.hostname.includes('carriersolution')) {
    return {
      message: i18n.t('shared.redirect.message_cs'),
      etrucknow: 'carriersolution.etrucknow.com',
      action: i18n.t('shared.redirect.action_cs'),
    }
  }
  return {
    message: i18n.t('shared.redirect.message_cv'),
    etrucknow: 'visibility.etrucknow.com',
    action: i18n.t('shared.redirect.action_cv'),
  }
}

const RedirectDialog = ({ open }: RedirectDialogProps): ReactElement => {
  const strings = getStrings()

  return (
    <KNDialog
      open={open}
      maxWidth="xs"
      preventClosing
      closeAction={false}
    >
      <DialogContentText component="div" sx={{ textAlign: 'center' }}>
        <KNTypography color="text.main" mb={2}>{strings.message}</KNTypography>
        <KNTypography component="p" mb={1} variant="h6" color="text.main" sx={{ textDecoration: 'line-through' }}>&nbsp;{strings.etrucknow}&nbsp;</KNTypography>
        <KNButton variant="contained" size="large" href={getRedirectLink()}>{strings.action}</KNButton>
      </DialogContentText>
    </KNDialog>
  )
}

export default RedirectDialog
